import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { connectToFirebase } from "./firebaseConnect";
import DateFilter from "@/filters/date";

import Fade from "@/components/shared/transitions/FadeTransition.vue";
import SnackCmp from "@/components/shared/Snack.vue";
import AuthService from "./msopenid";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("../firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}

Vue.config.productionTip = false;

Vue.use(Vuetify, {
  // iconfont: 'md' // 'md' || 'mdi' || 'fa' || 'fa4'
});
Vue.prototype.$auth = AuthService;

Vue.filter("date", DateFilter);

Vue.component("app-fade-transition", Fade);
Vue.component("app-snack", SnackCmp);

function autologin() {
  let user = Vue.prototype.$auth.getUser();
  if (user) {
    store.commit("user/setUser", user);
    connectToFirebase();
  }
}

autologin();

new Vue({
  router,
  store,
  render: (h) => h(App),
  async created() {
    this.$store.commit("setStartup", true);

    try {
      let user = this.$auth.getUser();
      if (user) {
        this.$store.commit("user/setUser", user);
      }
    } finally {
      this.$store.commit("setStartup", false);
    }
  },
}).$mount("#app");
