<template>
  <app-fade-transition mode="out-in">
    <v-container v-if="loading || !taskGroup" key="loadingOrError" fill-height>
      <v-layout align-center justify-center>
        <v-flex v-if="loading" xs12 sm6 md4 lg3 xl2>
          Loading Task Group
          <v-progress-linear indeterminate></v-progress-linear>
        </v-flex>
        <v-flex v-else xs12>
          <span class="error--text headline font-weight-bold">No task group</span>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container v-else key="taskGroup" fluid grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12 m12 v-if="isDeletedTasksPresent">
          <v-card>
            <v-card-text>
              <span class="error--text headline font-weight-bold">Tasks have been deleted!</span>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 m12 v-if="wasUpdated">
          <v-card>
            <v-card-text>
              <span class="warning--text headline font-weight-bold"
                >Task Group was updated. Please reload the page</span
              >
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 lg7>
          <app-info :taskGroup="taskGroup" :taskGroupEdit="taskGroupEdit"></app-info>
        </v-flex>

        <v-flex xs12 lg5>
          <related-groups :relatedGroups="relatedTaskGroups"></related-groups>
        </v-flex>

        <v-flex xs12 md6 lg4 v-if="snippetContainer">
          <app-task
            :taskContainer="snippetContainer"
            :taskGroup="taskGroup"
            :ref="snippetCardRef"
            @finished="onFinishedTask"
            @cancelled="onCancelledTask"
          >
          </app-task>
        </v-flex>

        <v-flex xs12 md6 lg4 v-for="taskContainer in taskContainersWithoutSnippet" :key="taskContainer.task.id">
          <app-task
            :taskContainer="taskContainer"
            :taskGroup="taskGroup"
            :ref="'taskCard_' + taskContainer.task.id"
            @finished="onFinishedTask"
            @cancelled="onCancelledTask"
          >
          </app-task>
        </v-flex>
      </v-layout>

      <app-confirm-dialog
        :show="showConfirmLeave"
        @leave="
          confirmedLeave = true;
          $router.push({ path: leaveTo.path, query: leaveTo.query });
        "
        @dismissed="showConfirmLeave = false"
      >
      </app-confirm-dialog>
    </v-container>
  </app-fade-transition>
</template>

<script>
import TaskGroupInfo from "@/components/taskGroup/TaskGroupInfo.vue";
import RelatedTaskGroups from "@/components/taskGroup/RelatedTaskGroups.vue";
import Task from "@/components/taskGroup/Task.vue";
import ConfirmLeaveDialog from "@/components/shared/ConfirmLeaveDialog.vue";
import { mapGetters, mapActions } from "vuex";
import * as util from "@/utilities/utilities";

export default {
  name: "taskGroup",

  props: ["id"],

  data() {
    return {
      leaveTo: null,
      confirmedLeave: false,
      showConfirmLeave: false,
    };
  },

  components: {
    appInfo: TaskGroupInfo,
    relatedGroups: RelatedTaskGroups,
    appTask: Task,
    appConfirmDialog: ConfirmLeaveDialog,
  },

  computed: {
    ...mapGetters("taskGroup", [
      "taskGroup",
      "taskGroupEdit",
      "relatedTaskGroups",
      "taskContainers",
      "loading",
      "isAnyTaskWorkedOnByUser",
      "isDeletedTasksPresent",
      "wasUpdated",
    ]),

    hasUnsavedChanges() {
      if (!this.taskGroup) {
        return false;
      }

      if (this.taskGroup.comment !== this.taskGroupEdit.comment) {
        return true;
      }

      const taskContainerWithOpenEdits = this.taskContainers.find((tc) => util.isTaskDifferent(tc.task, tc.taskEdit));

      return taskContainerWithOpenEdits !== undefined;
    },

    snippetContainer() {
      return this.taskContainers.find((taskContainer) => taskContainer.task.type === "snippet");
    },

    snippetCardRef() {
      if (!this.snippetContainer) {
        return undefined;
      }
      return "taskCard_" + this.snippetContainer.task.id;
    },

    taskContainersWithoutSnippet() {
      var collator = new Intl.Collator("en");
      return this.taskContainers
        .filter((taskContainer) => taskContainer.task.type !== "snippet")
        .sort((taskContainer1, taskContainer2) => {
          return collator.compare(taskContainer1.task.language, taskContainer2.task.language);
        });
    },

    allTaskContainers() {
      const containers = [...this.taskContainersWithoutSnippet];
      const snippet = this.snippetContainer;
      if (snippet) {
        containers.unshift(snippet);
      }
      return containers;
    },
  },

  methods: {
    ...mapActions(["showMessage"]),
    ...mapActions("taskGroup", ["fetchTaskGroup", "initEditOfTask", "getRelatedTaskGroups"]),

    async initTaskGroup(taskGroupId, editTaskId) {
      await this.fetchTaskGroup(taskGroupId);
      await this.getRelatedTaskGroups(taskGroupId);

      if (editTaskId !== undefined && editTaskId !== null && (editTaskId.length > 0 || Number.isInteger(editTaskId))) {
        await this.initEditOfTask(Number(editTaskId));
        setTimeout(() => this.findDefaultFocusAndScroll(Number(editTaskId)), 750);
      } else {
        setTimeout(this.findDefaultFocusAndScroll, 750);
      }

      // Stop the task-group page from opening the article view when running under cypress
      if (new URL(window.location.href).searchParams.get("cypressTest") === "1") {
        return;
      }

      this.openArticleView(taskGroupId);
    },

    onFinishedTask(ev) {
      console.log("onFinishedTask", ev);
      if (!this.isAnyTaskWorkedOnByUser) {
        this.$router.push("/tasks");
      }
    },

    onCancelledTask(ev) {
      console.log("onCancelledTask", ev);
      if (!this.isAnyTaskWorkedOnByUser) {
        this.$router.push("/tasks");
      }
    },

    focusAndScrollVueComponent(component) {
      if (component.defaultFocus()) {
        this.$vuetify.goTo(component, {
          duration: 300,
          offset: 0,
          easing: "easeOutCubic",
        });
        return true;
      }
      return false;
    },

    findDefaultFocusAndScroll(taskId) {
      const ids = this.allTaskContainers.map((taskContainer) => taskContainer.task.id);
      if (taskId !== undefined) {
        const index = ids.indexOf(taskId);
        ids.splice(index, 1);
        ids.unshift(taskId);
      }

      for (let id of ids) {
        let ref = this.$refs["taskCard_" + id];
        if (Array.isArray(ref)) {
          ref = ref[0];
        }
        if (ref) {
          if (this.focusAndScrollVueComponent(ref)) {
            return;
          }
        }
      }
    },

    beforePageUnload(ev) {
      // called when the page is exited/reloaded
      console.log("Unload", this.hasUnsavedChanges);
      if (this.hasUnsavedChanges) {
        ev.preventDefault();
        ev.returnValue = "";
      }
    },

    openArticleView(id) {
      // store task group in local storage so that article view can retrieve it
      window.localStorage.setItem("taskGroup", JSON.stringify(this.taskGroup));
      setTimeout(() => {
        window.localStorage.removeItem("taskGroup");
      }, 10000);

      window.open(
        "/articles/" + id,
        "articleWindow",
        "width=" + window.outerWidth * 0.8 + ", height=" + window.innerHeight * 0.9 + ", left=200, top=50"
      );
    },
  },

  created() {
    // console.log( 'CREATED', this.id, this.$route.query )

    window.addEventListener("beforeunload", this.beforePageUnload);
  },

  mounted() {
    // console.log( 'MOUNTED', this.$refs.snippetCard )

    this.initTaskGroup(this.id, this.$route.query.edit);
  },

  beforeDestroy() {
    // console.log( 'BEFORE DESTROY' )
    // otherwise the listeners accumulate
    window.removeEventListener("beforeunload", this.beforePageUnload);
  },

  beforeRouteUpdate(to, from, next) {
    console.log("BEFORE ROUTE UPDATE", to, from, this.id, this.$route.query);
    this.initTaskGroup(to.params.id, to.query.edit);
    next();
  },

  beforeRouteLeave(to, from, next) {
    console.log("BEFORE ROUTE LEAVE", to, from, this.id, this);
    if (!this.confirmedLeave && this.hasUnsavedChanges) {
      this.leaveTo = to;
      this.showConfirmLeave = true;
      next(false);
    } else {
      next();
    }
  },
};
</script>
