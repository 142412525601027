<template>
  <v-container>
    <v-layout row>
      <v-flex xs12 sm6 offset-sm3>
        <!-- <v-btn @click='test'>Test</v-btn> -->
        <v-card class="mt-3 py-3" color="blue lighten-1">
          <v-card-title class="white--text justify-center align-baseline pb-0">
            <span class="headline font-weight-bold mr-2">Welcome to Edifix</span>
          </v-card-title>
          <div class="white--text caption pb-4">version {{ getVersion }}</div>
          <v-card-text class="mt-3">
            <v-btn @click="loginPopup" flat outline color="white" autofocus>
              <!-- <v-icon left>mdi-login</v-icon> -->
              Login
            </v-btn>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "login",

  watch: {
    user() {
      // console.log( 'Watch', user, this.$route.query )
      if (this.user) {
        if (this.$route.query && this.$route.query.redirect) {
          this.$router.replace(this.$route.query.redirect);
        } else {
          this.$router.replace("/");
        }
      }
    },
  },

  data() {
    return {
      accessToken: null,
    };
  },

  computed: {
    ...mapGetters("user", ["user"]),

    getVersion() {
      // eslint-disable-next-line
            return APP_VERSION
    },

    isAuthenticated() {
      return this.user;
    },
  },

  methods: {
    ...mapMutations("user", ["setUser"]),
    ...mapActions(["showMessage"]),

    async test() {
      const auth = this.$auth;
      console.log(auth);
      this.accessToken = await this.$auth.getAccessToken();
      console.log("Access Token", this.accessToken);
    },

    async loginPopup() {
      try {
        const user = await this.$auth.loginPopup();
        if (user) {
          this.setUser(user);
        }
      } catch (error) {
        if (!("" + error).startsWith("user_cancelled")) {
          this.showMessage({
            title: "Login error",
            message: "" + error,
          });
        }
      }
    },

    logout() {
      this.$auth.logout();
    },
  },
};
</script>
