"use strict";

import { bootstrap } from "@/utilities/vuex";
import axios from "@/axios";
import { showMessage } from "@/utilities/utilities";
import _ from "lodash";

async function queryOrderSubjectsFromShape({ state, commit, dispatch }) {
  commit("setLoadingOrderSubjects", true);

  const params = {
    page: state.pagination.page - 1,
    size: state.pagination.rowsPerPage,
    sort: `${state.pagination.sortBy},${state.pagination.descending ? "desc" : "asc"}`,
  };

  Object.keys(state.filters).forEach((filterKey) => {
    let filter = state.filters[filterKey];
    if (filter === undefined || filter === null || filter.length === 0) {
      return;
    }

    params[filterKey] = `*${filter}*`;
  });

  try {
    const response = await axios.get("/order-subjects-from-shape", { params });
    console.log("success fetching order subjects", response);
    commit("setTotalOrderSubjects", response.data.page.totalElements);
    commit("setShapeOrderSubjects", response.data._embedded ? response.data._embedded.orderSubjects : []);
  } catch (error) {
    console.log("error fetching order subjects from shape", error);
    showMessage(dispatch, "Error while fetching order subjects", "" + error);
  } finally {
    commit("setLoadingOrderSubjects", false);
  }
}

export default bootstrap({
  namespaced: true,

  state: {
    pagination: {
      sortBy: "customerName",
      page: 1,
      rowsPerPage: 10,
    },
    filters: {
      customerName: "",
      name: "",
      orderNumber: "",
    },
    totalOrderSubjects: 0,
    selectedOrderSubject: null,
    orderSubjectEdits: null,
    shapeOrderSubjects: [],
    loadingOrderSubjects: false,
    loadingOrderSubject: false,
    saving: false,
  },

  actions: {
    fetchOrderSubjectsFromShape: _.debounce(queryOrderSubjectsFromShape, 1000, {
      leading: false,
      trailing: true,
    }),

    async fetchOrderSubject({ commit, dispatch }, shapeOrderSubjectId) {
      commit("setLoadingOrderSubject", true);
      try {
        const params = {
          returnDefault: true,
        };

        const response = await axios.get("/order-subjects/" + shapeOrderSubjectId, { params });
        console.log("success fetching order subject", response);
        commit("setSelectedOrderSubject", response.data);
        commit("setOrderSubjectEdits", _.cloneDeep(response.data));
      } catch (error) {
        console.log("error fetching order subject", error);
        showMessage(dispatch, "Error while fetching order subject data", "" + error);
      } finally {
        commit("setLoadingOrderSubject", false);
      }
    },

    async saveOrderSubject({ getters, commit, dispatch }) {
      commit("setSaving", true);
      try {
        const edits = getters.orderSubjectEdits;
        if (edits.id === undefined || edits.id === null) {
          // create new order subject
          const response = await axios.post("/order-subjects", edits);
          console.log("success creating order subject", response);
          commit("setSelectedOrderSubject", response.data);
          commit("setOrderSubjectEdits", _.cloneDeep(response.data));
        } else {
          // save edits
          const response = await axios.put("/order-subjects/" + edits.shapeOrderSubjectId, edits);
          console.log("success saving order subject", response);
          commit("setSelectedOrderSubject", response.data);
          commit("setOrderSubjectEdits", _.cloneDeep(response.data));
          showMessage(dispatch, "Success", "The changes have been saved", "success");
        }
      } catch (error) {
        console.log("error creating order subject", error);
        showMessage(dispatch, "Error while saving order subject", "" + error);
        throw error;
      } finally {
        commit("setSaving", false);
      }
    },

    async cancelOrderSubject({ getters, commit }) {
      commit("setOrderSubjectEdits", _.cloneDeep(getters.selectedOrderSubject));
    },
  },
});
