<template>
  <v-container fluid grid-list-lg>
    <v-layout row wrap>
      <v-flex xs12 sm4 lg3>
        <app-order-subject-table
          ref="orderSubjectsTable"
          :orderSubjects="shapeOrderSubjects"
          :selectedOrderSubject="selectedOrderSubject"
          :loadingOrderSubjects="loadingOrderSubjects"
          @clickedOrderSubject="onClickOrderSubject"
          @reloadRequested="fetchOrderSubjectsFromShape"
          :autofocus="id === undefined"
        >
        </app-order-subject-table>
      </v-flex>
      <v-flex xs12 sm8 lg9>
        <app-order-subject-details
          ref="orderSubjectDetails"
          :orderSubject="orderSubjectEdits"
          :hasUnsavedChanges="hasUnsavedChanges"
          :autofocus="id !== undefined"
        >
        </app-order-subject-details>
      </v-flex>
    </v-layout>

    <app-confirm-dialog
      :show="showConfirmLeavePage || showConfirmLeaveSelection"
      @leave="onConfirmedLeave"
      @dismissed="showConfirmLeavePage = showConfirmLeaveSelection = false"
    >
    </app-confirm-dialog>
  </v-container>
</template>

<script>
import OrderSubjectTable from "@/components/orderSubjects/OrderSubjectTable.vue";
import OrderSubjectDetails from "@/components/orderSubjects/OrderSubjectDetails.vue";
import ConfirmLeaveDialog from "@/components/shared/ConfirmLeaveDialog.vue";
import { mapGetters, mapActions } from "vuex";
import { diffObjects } from "@/utilities/utilities";

export default {
  name: "OrderSubjects",

  props: ["id"],

  components: {
    appOrderSubjectTable: OrderSubjectTable,
    appOrderSubjectDetails: OrderSubjectDetails,
    appConfirmDialog: ConfirmLeaveDialog,
  },

  data() {
    return {
      leaveTo: null,
      leaveToSelection: null,
      confirmedLeave: false,
      showConfirmLeavePage: false,
      showConfirmLeaveSelection: false,
    };
  },

  computed: {
    ...mapGetters("orderSubjects", [
      "shapeOrderSubjects",
      "selectedOrderSubject",
      "orderSubjectEdits",
      "loadingOrderSubjects",
      "saving",
    ]),

    hasUnsavedChanges() {
      const diff = diffObjects(this.selectedOrderSubject, this.orderSubjectEdits);
      // console.log( 'hasUnsavedChanges', Object.keys( diff ).length > 0, diff )
      return Object.keys(diff).length > 0;
    },
  },

  methods: {
    ...mapActions("orderSubjects", ["fetchOrderSubject", "fetchOrderSubjectsFromShape"]),

    async init(shapeOrderSubjectId) {
      const requestAll = this.fetchOrderSubjectsFromShape();
      if (shapeOrderSubjectId !== undefined && shapeOrderSubjectId !== null) {
        await this.setSelectedOrderSubject(shapeOrderSubjectId);
      }
      await requestAll;
    },

    async onClickOrderSubject(orderSubjectShape) {
      if (this.saving) {
        return;
      }
      if (this.hasUnsavedChanges) {
        this.showConfirmLeaveSelection = true;
        this.leaveToSelection = orderSubjectShape.id;
      } else {
        this.setSelectedOrderSubject(orderSubjectShape.id);
      }
    },

    async setSelectedOrderSubject(shapeOrderSubjectId) {
      await this.fetchOrderSubject(shapeOrderSubjectId);
      if (this.selectedOrderSubject) {
        this.$router.replace("/order-subjects/" + this.selectedOrderSubject.shapeOrderSubjectId);
      } else {
        this.$router.replace("/order-subjects");
      }
    },

    onConfirmedLeave() {
      this.confirmedLeave = true;
      if (this.showConfirmLeavePage) {
        this.$router.push({
          path: this.leaveTo.path,
          query: this.leaveTo.query,
        });
      } else if (this.showConfirmLeaveSelection) {
        this.setSelectedOrderSubject(this.leaveToSelection);
      }
    },

    beforePageUnload(ev) {
      // called when the page is exited/reloaded
      console.log("Unload", this.hasUnsavedChanges);
      if (this.hasUnsavedChanges) {
        ev.preventDefault();
        ev.returnValue = "";
      }
    },
  },

  created() {
    console.log("CREATED", this.id);

    window.addEventListener("beforeunload", this.beforePageUnload);

    this.init(this.id);
  },

  beforeDestroy() {
    // console.log( 'BEFORE DESTROY' )
    // otherwise the listeners accumulate
    window.removeEventListener("beforeunload", this.beforePageUnload);
  },

  beforeRouteUpdate(to, from, next) {
    console.log("BEFORE ROUTE UPDATE", to, from, this.id);
    // this.initTaskGroup( to.params.id, to.query.edit )
    next();
  },

  beforeRouteLeave(to, from, next) {
    console.log("BEFORE ROUTE LEAVE", to, from, this.id);
    if (!this.confirmedLeave && this.hasUnsavedChanges) {
      this.leaveTo = to;
      this.showConfirmLeavePage = true;
      next(false);
    } else {
      next();
    }
  },
};
</script>
