import store from "../store";

export default (to, from, next) => {
  if (store.getters["user/user"]) {
    next();
  } else {
    next({
      path: "/auth/login",
      query: {
        redirect: to.fullPath,
      },
      replace: true,
    });
  }
};
