import Vue from "vue";
import Router from "vue-router";
import AuthGuard from "./auth-guard";
const Login = async () => await require(/* webpackChunkName: 'auth' */ "@/views/Login.vue");
const Tasks = async () => await require(/* webpackChunkName: 'tasks' */ "@/views/Tasks.vue");
const OrderSubjects = async () => await require(/* webpackChunkName: 'order-subjects' */ "@/views/OrderSubjects.vue");
const ArticleView = async () => await require(/* webpackChunkName: 'articles' */ "@/views/Articles.vue");
const TaskGroup = async () => await require(/* webpackChunkName: 'task-group' */ "@/views/TaskGroup.vue");

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.PUBLIC_PATH,
  routes: [
    {
      path: "*",
      redirect: "/tasks",
      beforeEnter: AuthGuard,
    },
    {
      path: "/tasks",
      name: "tasks",
      component: Tasks,
      beforeEnter: AuthGuard,
    },
    {
      path: "/task-groups/:id",
      name: "taskGroup",
      component: TaskGroup,
      props: true,
      beforeEnter: AuthGuard,
    },
    {
      path: "/order-subjects/:id?",
      name: "orderSubjects",
      component: OrderSubjects,
      props: true,
      beforeEnter: AuthGuard,
    },
    {
      path: "/articles/:id",
      name: "articles",
      component: ArticleView,
      props: true,
      beforeEnter: AuthGuard,
    },
    {
      path: "/auth",
      name: "auth",
      redirect: "/auth/login",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "login",
          name: "login",
          component: Login,
        },
      ],
    },
  ],
});
