"use strict";

import axios from "@/axios";
import firebase from "firebase/app";
import "firebase/messaging";
import { updateStoreByMessage } from "./utilities/firebase";

export function connectToFirebase() {
  /**
   * @see vue.config.js
   */
  const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
  };

  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
  }
  const messaging = firebase.messaging();

  Notification.requestPermission().then(function () {
    return messaging
      .getToken()
      .then(function (token) {
        axios
          .post("/subscriptions", {
            identifier: token,
          })
          .then(() => {
            console.log("success in subscribing");
          });
      })
      .catch(function (err) {
        console.log("Error! :: " + err);
      });
  });

  messaging.onMessage(function (payload) {
    updateStoreByMessage(payload.data);
  });
}
