export function transformTask(params) {
  return {
    projection: params.projection,
    orderSubject: {
      customer: params["orderSubject.customer"],
      name: params["orderSubject.name"],
    },
    taskGroup: {
      leadArticleMediaName: params["taskGroup.leadArticleMediaName"],
      priority: params["taskGroup.priority"],
    },
    type: params.type,
    language: params.language,
    status: params.status,
    author: params.author,
    reviewer: params.reviewer,
    emptyFilter: params.emptyFilter,
    page: params.page,
    size: params.size,
  };
}

export function transformTaskFilters(params) {
  return {
    orderSubject: {
      customer: params["orderSubject.customer"],
      name: params["orderSubject.name"],
    },
    taskGroup: {
      leadArticleMediaName: params["taskGroup.leadArticleMediaName"],
      priority: params["taskGroup.priority"],
    },
    type: params.type,
    language: params.language,
    status: params.status,
    author: params.author,
    reviewer: params.reviewer,
    emptyFilter: params.emptyFilter,
  };
}
