<template>
  <v-container fluid fill-height v-resize="onWindowResize">
    <app-fade-transition mode="out-in">
      <v-layout v-if="loading || !taskGroup" key="loadingOrError" align-center justify-center>
        <v-flex v-if="loading" xs12 sm6 md4 lg3 xl2>
          Loading Task Group
          <v-progress-linear indeterminate></v-progress-linear>
        </v-flex>
        <v-flex v-else xs12>
          <span class="error--text headline font-weight-bold">No task group</span>
        </v-flex>
      </v-layout>

      <v-layout v-else key="main" row>
        <v-flex xs12>
          <v-tabs v-model="selectedTab" color="white" slider-color="primary" class="elevation-1" fixed-tabs show-arrows>
            <v-tab v-for="(item, index) in articles" :key="item.id" ripple @click="tabClicked(index)">
              Article {{ index + 1 }}
              <v-icon v-if="item.refEditorialProducts.length > 0" small class="ml-1">check </v-icon>
            </v-tab>

            <v-tab-item v-for="item in articles" style="background-color: white" :key="item.id" class="pa-3">
              <v-layout row>
                <vue-draggable-resizable
                  :parent="false"
                  :handles="showRightSide ? ['mr'] : []"
                  class="grow ma-1"
                  class-name="resizable"
                  :draggable="false"
                  :active="true"
                  @resizing="onResizableResize"
                  :preventDeactivation="true"
                  :w="windowWidth / 3"
                  :maxWidth="Math.max(200, windowWidth - 300)"
                  :h="100"
                >
                  <v-data-table
                    :headers="headers"
                    :items="item.refEditorialProducts"
                    :indeterminate="true"
                    :loading="loading"
                    class="elevation-1 mb-3"
                    no-data-text="No abstracts or headlines found"
                  >
                    <template slot="no-data">
                      <v-alert v-if="item.errorAbstracts" :value="true" color="error">
                        <h3>Error loading referenced editorial products:</h3>
                        {{ item.errorAbstracts.message }}
                      </v-alert>
                    </template>
                    <template slot="items" slot-scope="props">
                      <tr
                        class="abstract-item"
                        :class="[
                          {
                            selected: selectedAbstract && props.item.id === selectedAbstract.id,
                          },
                        ]"
                        @click="abstractItemClick(props.item)"
                      >
                        <td class="text-xs-left">{{ props.item.customer }}</td>
                        <td class="text-xs-left">{{ props.item.name }}</td>
                        <td class="text-xs-left">{{ props.item.language }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                  <v-card v-if="item.article" flat>
                    <v-card-title class="text-xs-left px-0">
                      <v-container grid-list-md text-xs-center v-if="item.reference">
                        <v-layout align-start justify-space-between row>
                          <v-flex xs6>
                            {{ item.reference.mediaName }}
                          </v-flex>
                          <v-flex xs6>
                            {{ item.reference.publishedAt | date }}
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <div>
                        <v-avatar color="teal" size="20px" style="vertical-align: super" class="mr-2">
                          <span class="white--text caption">{{ taskGroup.leadArticleLanguage }}</span>
                        </v-avatar>
                        <span class="headline font-weight-medium">{{ item.article.headline }}</span>
                      </div>
                      <div v-if="item.article.subHeadline" class="subheading">
                        {{ item.article.subHeadline }}
                      </div>
                      <div>
                        <a v-if="item.article.deeplink" v-bind:href="item.article.deeplink" target="_blank">{{
                          item.article.deeplink
                        }}</a>
                      </div>
                    </v-card-title>

                    <v-card-text class="px-0 text-xs-left ocr-area">
                      <p v-html="item.article.content"></p>
                    </v-card-text>
                  </v-card>
                  <v-alert v-else-if="item.errorArticle" :value="true" color="error">
                    <h3>Error loading article:</h3>
                    {{ item.errorArticle.message }}
                  </v-alert>
                </vue-draggable-resizable>

                <div v-if="showRightSide" style="margin-left: 32px; overflow: hidden; width: 100%">
                  <v-card v-if="selectedAbstract" class="abstract-area text-xs-left ma-1 mb-3">
                    <v-card-text v-if="selectedAbstract.status === 'done'" text>
                      <i
                        aria-hidden="true"
                        class="v-icon notranslate v-alert__icon mdi mdi-check-circle theme--light success--text float-right"
                      ></i>
                    </v-card-text>

                    <v-card-title
                      v-if="selectedAbstract.showHeadline"
                      class="mr-0"
                      ref="headline"
                      style="overflow: auto; padding-right: 56px"
                      v-html="selectedAbstract.headline"
                    >
                    </v-card-title>
                    <v-card-text
                      class="mr-0"
                      ref="abstract"
                      style="height: 12em; overflow: auto; padding-right: 56px"
                      v-html="selectedAbstract.abstract"
                    >
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom open-delay="1000">
                        <v-btn icon slot="activator" class="mt-3 mr-2" @click="copyToClipboard">
                          <v-icon color="secondary">mdi-content-copy</v-icon>
                        </v-btn>
                        <span>Copy abstract to clipboard</span>
                      </v-tooltip>
                    </v-card-actions>
                  </v-card>
                  <div class="ma-1" style="height: 100%">
                    <viewer :options="viewerSettings" :images="item.article.imagesUrls" ref="v-viewer">
                      <template slot-scope="scope">
                        <img
                          v-for="(url, index) in scope.images"
                          :src="url"
                          :key="url"
                          class="clipping-src"
                          v-show="index <= 0"
                        />
                      </template>
                    </viewer>
                  </div>
                </div>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-layout>
    </app-fade-transition>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import VueDraggableResizable from "vue-draggable-resizable";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
  name: "articles",

  props: ["id"],

  components: {
    viewer: Viewer,
    vueDraggableResizable: VueDraggableResizable,
  },

  data() {
    return {
      viewerSettings: {
        focus: false,
        inline: true,
        className: "zoom-container",
        toolbar: false,
      },
      windowWidth: 0,
      headers: [
        {
          text: "Client",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "Order Subject", value: "orderSubject" },
        { text: "Language", value: "language" },
      ],
    };
  },

  computed: {
    ...mapGetters("articles", ["taskGroup", "loading", "loadError", "articles", "selectedArticle", "selectedAbstract"]),

    showRightSide() {
      const article = this.articles[this.selectedTab].article;
      return this.selectedAbstract || (article && article.imagesUrls && article.imagesUrls.length > 0);
    },

    selectedTab: {
      get() {
        return this.$store.getters["articles/selectedTab"];
      },
      set(v) {
        this.$store.commit("articles/setSelectedTab", v);
      },
    },
  },

  methods: {
    ...mapActions(["showMessage"]),
    ...mapActions("articles", ["init", "fetchArticles"]),
    ...mapMutations("articles", ["setSelectedAbstract"]),

    async initArticle(taskGroupId) {
      if (this.taskGroup == null) {
        await this.init(taskGroupId);
      }
    },

    tabClicked(tabIndex) {
      console.log("tab clicked: ", tabIndex);
      this.setSelectedAbstract(null);
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
        const viewer = document.getElementsByClassName("viewer-active");

        for (let i = 0; i < viewer.length; i++) {
          viewer[i].children[0].click();
        }
      }, 200);
    },

    onResizableResize() {
      // console.log( 'onResize' )
      window.dispatchEvent(new Event("resize"));
    },

    onWindowResize() {
      this.windowWidth = document.documentElement.clientWidth;
    },

    abstractItemClick(abstractItem) {
      // console.log( 'Clicked view', abstractItem )
      this.setSelectedAbstract(abstractItem);
    },

    async copyToClipboard() {
      const abstractCmp = this.$refs.abstract[this.selectedTab];
      console.log(abstractCmp);

      const item = new navigator.clipboard.ClipboardItem({
        "text/html": new Blob([abstractCmp.innerHTML], { type: "text/html" }),
        "text/plain": new Blob([abstractCmp.innerText], { type: "text/plain" }),
      });

      await navigator.clipboard.write([item]);
      this.showMessage({
        title: "Success",
        message: "Copied text to clipboard",
        color: "success",
        timeout: 2000,
      });
    },
  },

  created() {
    this.initArticle(this.$route.params.id);
  },

  mounted() {
    this.onWindowResize();
  },
};
</script>

<style lang="scss">
.handle.handle-mr {
  position: absolute;
  border-top: none;
  border-bottom: none;
  border-left: none;
  // border-right: 1px solid var(--v-secondary-lighten5);
  border-right: 1px solid var(--v-primary-base);
  background-color: transparent;
  height: 100%;
  width: 6px;

  top: 0;
  margin-top: 0;
  right: -20px;
  cursor: col-resize;
}

.abstract-area p {
  margin-bottom: 0;
}

.abstract-item {
  cursor: pointer;
}

.ocr-area p {
  margin-bottom: 0;
}
</style>

<style scoped lang="scss">
.selected {
  border-left: 10px solid #673ab7;
}

.resizable {
  position: relative !important;
  height: 100% !important;
  min-width: 200px !important;
}

// .abstract-area {
//     height: 12em;
//     overflow: auto;
// }

.clipping-src {
  filter: blur(4px);
  width: 85%;
}

.ocr-area {
  overflow: scroll;
  overflow-x: hidden;
  max-height: 500px;
}
</style>
