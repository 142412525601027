<template>
  <v-data-table
    id="table-order-subjects"
    :headers="headers"
    :items="orderSubjects"
    :loading="loadingOrderSubjects"
    :pagination.sync="pagination"
    :totalItems="totalOrderSubjects"
    no-data-text="No Order Subjects found"
    :rows-per-page-items="[5, 10, 25]"
    class="elevation-1"
  >
    <template slot="headers" slot-scope="props">
      <tr>
        <th
          v-for="header in props.headers"
          :key="header.text"
          :class="[
            'column sortable',
            pagination.descending ? 'desc' : 'asc',
            header.value === pagination.sortBy ? 'active' : '',
          ]"
          @click="changeSort(header.value)"
        >
          <v-tooltip bottom open-delay="2000">
            <div slot="activator" style="display: inline-block" class="pt-3">
              {{ header.text }}
            </div>
            <span>
              {{ header.tooltip }}
            </span>
          </v-tooltip>
          <v-icon small class="ml-1">arrow_upward</v-icon>
          <v-text-field
            @click.stop=""
            :autofocus="autofocus && header.value === 'customerName'"
            v-model="filters[header.value]"
            @input="$emit('reloadRequested')"
            class="pa-0 mx-0 mb-0 mt-1"
          >
          </v-text-field>
        </th>
      </tr>
    </template>

    <template slot="items" slot-scope="props">
      <!-- <tr v-if='props.index === 0'>
                <v-text-field></v-text-field>
            </tr>
            <tr v-else -->
      <tr
        :class="[
          {
            selected: selectedOrderSubject && props.item.id == selectedOrderSubject.shapeOrderSubjectId,
          },
          'order-subject',
        ]"
        :active="selectedOrderSubject && props.item.id == selectedOrderSubject.shapeOrderSubjectId"
        @click="$emit('clickedOrderSubject', props.item)"
      >
        <td v-ripple class="text-xs-left">{{ props.item.customerName }}</td>
        <td v-ripple class="text-xs-left">{{ props.item.name }}</td>
        <td v-ripple class="text-xs-left">{{ props.item.orderNumber }}</td>
        <!-- <td class='justify-center px-0'>
                    <v-btn v-if='isEditable(props.item)'
                           icon
                           @click.prevent.stop='editItem(props.item)'>
                        <v-icon small>
                            edit
                        </v-icon>
                    </v-btn>
                </td> -->
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["orderSubjects", "selectedOrderSubject", "loadingOrderSubjects", "autofocus"],

  data() {
    return {
      headers: [
        {
          text: "Client",
          tooltip: "Kundenkurzname",
          align: "left",
          sortable: true,
          class: "string",
          value: "customerName",
        },
        {
          text: "Order Subject",
          tooltip: "Auftragsthema",
          align: "left",
          sortable: false,
          class: "string",
          value: "name",
        },
        {
          text: "Number",
          tooltip: "Auftragsnummer",
          align: "left",
          sortable: false,
          class: "string",
          value: "orderNumber",
        },
      ],
    };
  },

  watch: {
    pagination: {
      handler() {
        // console.log( 'Pagination changed', value )

        this.$emit("reloadRequested");
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("orderSubjects", ["totalOrderSubjects", "filters"]),

    pagination: {
      get() {
        return this.$store.getters["orderSubjects/pagination"];
      },

      set(value) {
        this.$store.commit("orderSubjects/setPagination", value);
      },
    },
  },

  methods: {
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },

    focusFilter() {
      this.$refs.filter_customerName[0].focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.selected {
  border-left: 10px solid #673ab7;
}
</style>

<style lang="scss">
#table-order-subjects .v-text-field.v-input input[type="text"] {
  font-size: 0.8em;
  color: var(--v-secondary-text-color) !important;
  padding: 0;
}

table tr.order-subject {
  cursor: pointer;
}
</style>
