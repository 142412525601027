<template>
  <v-dialog v-model="showInternal" max-width="400">
    <v-card class="text-xs-left">
      <v-card-title class="headline">Unsaved Changes</v-card-title>
      <v-card-text>
        There are unsaved changes on the page. If you proceed these changes will be lost. Do you still want to leave?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="$emit('dismissed')"> Stay </v-btn>
        <v-btn
          flat
          color="secondary"
          @click="
            $emit('leave');
            $emit('dismissed');
          "
        >
          Leave anyway
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["show"],

  watch: {
    show(value) {
      // watch for changes to the outside controlled visibility and store them inside this component
      this.showInternal = value;
    },

    showInternal(value) {
      // watch for changes to the inside controlled visibility and inform outside world if component was closed
      if (!value) {
        this.$emit("dismissed");
      }
    },
  },

  data() {
    return {
      showInternal: this.show,
    };
  },
};
</script>
