<template>
  <v-app :style="cssTheme">
    <v-fade-transition mode="out-in">
      <v-container v-if="startup || startupError" key="startupOrError" fill-height>
        <v-layout align-center justify-center>
          <v-flex v-if="startup" xs12 sm6 md4 lg3 xl2>
            {{ $route.name === "articles" ? "Loading Articles" : "Starting Edifix" }}
            <v-progress-linear indeterminate></v-progress-linear>
          </v-flex>
          <v-flex v-else xs12>
            <span class="error--text headline font-weight-bold">{{ startupError }}</span>
          </v-flex>
        </v-layout>
      </v-container>

      <v-container fill-height fluid class="pa-0" v-else>
        <v-layout>
          <v-navigation-drawer
            v-if="$route.name && !$route.name.startsWith('articles')"
            v-model="sideNav"
            temporary
            fixed
            app
          >
            <v-list>
              <v-list-tile avatar>
                <v-list-tile-avatar color="teal">
                  <span class="white--text" :class="initials.length === 1 ? 'headline' : 'subheading'">{{
                    initials
                  }}</span>
                </v-list-tile-avatar>
                <v-list-tile-content v-if="userIsAuthenticated">
                  {{ user.name }}
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
              <template v-if="userIsAuthenticated">
                <v-list-tile v-for="item in menuItems" :key="item.title" router :to="item.link">
                  <v-list-tile-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    {{ item.title }}
                  </v-list-tile-content>
                </v-list-tile>
              </template>

              <v-list-tile v-if="userIsAuthenticated" @click="onLogout">
                <v-list-tile-action>
                  <v-icon>mdi-logout</v-icon>
                </v-list-tile-action>
                <v-list-tile-content> Logout </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-navigation-drawer>

          <v-toolbar
            v-if="$route.name && !$route.name.startsWith('articles')"
            prominent
            fixed
            app
            style="background: #fafafa"
          >
            <v-toolbar-side-icon v-if="userIsAuthenticated" @click="sideNav = !sideNav" class="hidden-md-and-up mr-3">
            </v-toolbar-side-icon>
            <v-toolbar-title class="ml-0">
              <router-link to="/" tag="span" style="cursor: pointer">
                <img alt="Unicepta logo" class="mt-2" height="36" src="./assets/logo.svg" />
              </router-link>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-toolbar-items class="hidden-sm-and-down">
              <template v-if="userIsAuthenticated">
                <v-btn flat v-for="item in menuItems" :key="item.title" router :to="item.link">
                  <v-icon left>{{ item.icon }}</v-icon>
                  {{ item.title }}
                </v-btn>
              </template>

              <v-btn v-if="userIsAuthenticated" flat @click="onLogout">
                <v-icon left>mdi-logout</v-icon>
                Logout
              </v-btn>

              <v-layout align-center class="ml-3">
                <v-tooltip bottom>
                  <v-avatar slot="activator" color="teal" size="40">
                    <span
                      style="cursor: default"
                      class="white--text"
                      :class="initials.length === 1 ? 'headline' : 'subheading'"
                      >{{ initials }}</span
                    >
                  </v-avatar>
                  <span>{{ userIsAuthenticated ? user.name : "Login first" }}</span>
                </v-tooltip>
              </v-layout>
            </v-toolbar-items>
          </v-toolbar>

          <v-content fluid fill-height>
            <v-layout fill-height>
              <v-flex xs12>
                <router-view></router-view>
              </v-flex>
            </v-layout>
          </v-content>
        </v-layout>
      </v-container>
    </v-fade-transition>

    <app-snack
      :title="messageTitle"
      :message="messageMessage"
      :color="messageColor"
      :timeout="messageTimeout"
      :show="showMessage"
      @dismissed="onDismissed"
    ></app-snack>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      sideNav: false,
    };
  },

  computed: {
    ...mapGetters([
      "startup",
      "startupError",
      "messageTitle",
      "messageColor",
      "messageMessage",
      "messageTimeout",
      "showMessage",
    ]),
    ...mapGetters("user", ["user"]),

    /**
     * https://github.com/vuetifyjs/vuetify/issues/4817
     */
    cssTheme() {
      const out = {};

      // for (const name of Object.keys(this.$vuetify.theme)) {
      //     out[`--v-color-${name}`] = this.$vuetify.theme[name];
      // }

      out["--v-primary-text-color"] = "rgba(0,0,0,.87)";
      out["--v-secondary-text-color"] = "rgba(0,0,0,.54)";

      return out;
    },

    menuItems() {
      let menuItems = [
        {
          icon: "supervisor_account",
          title: "Tasks",
          link: "/tasks",
        },
        {
          icon: "mdi-settings",
          title: "Order Subjects",
          link: "/order-subjects",
        },
      ];
      return menuItems;
    },

    userIsAuthenticated() {
      return this.user !== null && this.user !== undefined;
    },

    initials() {
      if (this.user === null || this.user === undefined) {
        return "?";
      }
      const name = this.user.name;
      if (name !== undefined && name !== null) {
        const parts = name.toUpperCase().split(" ");
        let initials = "";
        for (let i = 0; i < Math.min(2, parts.length); i++) {
          const part = parts[i];
          if (part.length > 0) {
            initials += part.charAt(0);
          }
        }
        return initials;
      }
      return "?";
    },
  },

  methods: {
    onDismissed() {
      // inform store that global error component is closed again
      this.$store.commit("setShowMessage", false);
    },

    onLogout() {
      this.$auth.logout();
    },
  },

  created() {
    // eslint-disable-next-line
        console.log('You are running version', APP_VERSION)
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    // color: #2c3e50;
    //&.router-link-exact-active {
    // color: #42b983;
    //}
  }
}
</style>
