import Vue from "vue";
import Vuex from "vuex";

import taskGroup from "@/store/modules/taskGroup";
import tasks from "@/store/modules/tasks";
import orderSubjects from "@/store/modules/orderSubjects";
import articles from "@/store/modules/articles";
import user from "@/store/modules/user";
import { bootstrap } from "@/utilities/vuex";

Vue.use(Vuex);

export default new Vuex.Store(
  bootstrap({
    // strict: process.env.NODE_ENV !== 'production',

    modules: {
      user,
      tasks,
      taskGroup,
      orderSubjects,
      articles,
    },

    state: {
      startup: false,
      startupError: null,
      showMessage: false,
      messageTitle: null,
      messageMessage: null,
      messageColor: null,
      messageTimeout: null,
    },

    actions: {
      showMessage({ commit }, payload) {
        commit("setShowMessage", false);
        commit("setMessageTitle", payload.title);
        commit("setMessageMessage", payload.message);
        commit("setMessageColor", payload.color);
        commit("setMessageTimeout", payload.timeout);
        commit("setShowMessage", true);
      },
    },
  })
);
