"use strict";

import { bootstrap } from "@/utilities/vuex";
import axios from "@/axios";

export default bootstrap({
  namespaced: true,

  state: {
    loading: false,
    user: null,
    // user: {
    //     name: 'jschoppenhorst',
    //     givenName: 'Jan',
    //     displayName: 'Jan Schoppenhorst'
    // }
  },

  actions: {
    login({ commit, dispatch }, payload) {
      commit("setLoading", true);
      commit("setUser", null);
      return axios
        .post("/login", {
          username: payload.username,
          password: payload.password,
        })
        .then((result) => {
          console.log("success login user", result);
          commit("setUser", result.data);
        })
        .catch((error) => {
          let title = null;
          let message = "";
          if (error.response && error.response.status === 401) {
            title = "Wrong credentials";
            message = "Username and/or Password are invalid!";
          } else {
            title = "Error during login";
            message += error;
          }
          dispatch(
            "showMessage",
            {
              title,
              message,
            },
            { root: true }
          );
        })
        .then(() => {
          commit("setLoading", false);
        });
    },

    // loadUserSilent( { commit, dispatch } ) {
    //     commit( 'setLoading', true )
    //     return axios
    //         .get( '/user' )
    //         .then( result => {
    //             console.log( 'success fetching user', result )
    //             commit( 'setUser', result.data.user )
    //         } )
    //         .catch( error => {
    //             if ( error.response && ( error.response.status === 401 || error.response.status === 404 ) ) {
    //                 return
    //             }
    //             dispatch(
    //                 'showMessage',
    //                 {
    //                     title: 'Error during probing for logged in user',
    //                     message: '' + error
    //                 },
    //                 { root: true }
    //             )
    //             commit( 'setUser', null )
    //         } )
    //         .then( () => {
    //             commit( 'setLoading', false )
    //         } )
    // },

    testSecret({ commit }) {
      axios
        .get("/user")
        .then((result) => {
          console.log(result);
          commit("setUser", result.data.user);
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    },

    logout({ commit, dispatch }) {
      commit("setLoading", true);
      return axios
        .post("/logout")
        .then((result) => {
          console.log("success logout user", result);
          commit("setUser", null);
        })
        .catch((error) => {
          dispatch(
            "showMessage",
            {
              title: "Error during logout",
              message: "" + error,
            },
            { root: true }
          );
        })
        .then(() => {
          commit("setLoading", false);
        });
    },
  },
});
