<template>
  <div>
    <v-snackbar
      v-model="showInternal"
      :color="color || 'error'"
      :top="true"
      :auto-height="true"
      :multi-line="true"
      :timeout="timeout"
      :vertical="true"
    >
      <h3 v-if="title">{{ title }}</h3>
      <div v-if="message">{{ message }}</div>
      <v-btn flat dark @click="$emit('dismissed')">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String,
    message: String,
    color: String,
    timeout: {
      type: Number,
      default: 6000,
    },
  },

  watch: {
    show(value) {
      // watch for changes to the outside controlled visibility and store them inside this component
      this.showInternal = value;
    },

    showInternal(value) {
      // watch for changes to the inside controlled visibility and inform outside world if component was closed
      if (!value) {
        this.$emit("dismissed");
      }
    },
  },

  data() {
    return {
      showInternal: this.show,
    };
  },
};
</script>

<style>
.v-snack__content {
  white-space: pre-line;
}
</style>
