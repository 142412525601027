"use strict";

// TimeoutError: ResourceRequest timed out: https://github.com/sequelize/sequelize/issues/7884

import { bootstrap } from "@/utilities/vuex";
import { transformTask, transformTaskFilters } from "@/utilities/transforms";
import axios from "@/axios";
import _ from "lodash";

const collator = new Intl.Collator("en");

async function queryTasks({ state, commit, dispatch }) {
  commit("setLoadingTasks", true);

  const params = {
    projection: "table",
    page: state.pagination.page - 1,
    size: state.pagination.rowsPerPage,
  };

  Object.values(state.filters).forEach((filter) => {
    if (!filter.parameter) {
      return;
    }

    if (filter.initialPageLoadWithUrlFiltersTaskLoad || filter.values.length !== filter.selected.length) {
      if (filter.selected.length === 0) {
        if (!params.emptyFilter) {
          params.emptyFilter = [];
        }
        params.emptyFilter.push(filter.parameter);
      } else {
        params[filter.parameter] = filter.selected;
      }
    }
  });

  const body = transformTask(params);

  return axios
    .post("/tasks/search", body)
    .then((result) => {
      // update pagination
      commit("setTotalTasks", result.data.page.totalElements);

      // update task list
      commit("setTasks", result.data._embedded.tasks);

      console.log("success fetching task", result.data);
    })
    .catch((error) => {
      dispatch(
        "showMessage",
        {
          title: "Error while fetching task data",
          message: "" + error,
        },
        { root: true }
      );
    })
    .then(() => {
      commit("setLoadingTasks", false);
      Object.values(state.filters).forEach((filter) => {
        filter.initialPageLoadWithUrlFiltersTaskLoad = false;
      });
    });
}

function queryFilters({ state, commit, dispatch }) {
  commit("setLoadingFilters", true);

  const params = {};

  Object.values(state.filters).forEach((filter) => {
    if (filter.parameter && filter.values.length !== filter.selected.length) {
      if (filter.selected.length === 0) {
        if (!params.emptyFilter) {
          params.emptyFilter = [];
        }
        params.emptyFilter.push(filter.parameter);
      } else {
        params[filter.parameter] = filter.selected;
      }
    }
  });

  // Update selection cache
  Object.values(state.filters).forEach((filter) => {
    if (filter.responseKey) {
      filter.values.forEach((value) => {
        filter.selectionCache[value] = filter.selected.includes(value);
      });
    }
  });

  const body = transformTaskFilters(params);

  return axios
    .post("/task-filters/search", body)
    .then((result) => {
      console.log("success fetching filters", result.data);

      // update filters
      Object.values(state.filters).forEach((filter) => {
        if (filter.responseKey) {
          let distinctValues = result.data._embedded[filter.responseKey];

          if (distinctValues) {
            // get list of non-selected values of pre change state
            const nonSelected = _.difference(filter.values, filter.selected);
            // filter.values = distinctValues.sort( collator.compare )
            // remove non-selected from selected

            // replace null with empty string
            filter.values = distinctValues.map((it) => (it === null ? "" : it)).sort(collator.compare);
            if (filter.autoselect) {
              filter.selected = _.difference(filter.values.slice(), nonSelected);
              filter.selected = filter.values.filter((filterKey) => {
                // console.log( filterKey )
                if (filter.initialPageLoadWithUrlFiltersFilterLoad) {
                  // First page load with filters set in url -> Keep only selected
                  return filter.selected.includes(filterKey);
                } else {
                  return filter.selectionCache[filterKey] === true || filter.selectionCache[filterKey] === undefined;
                }
              });
            }
          }
          filter.initialPageLoadWithUrlFiltersFilterLoad = false;

          if (filter.initialAutoselectEnableOnce) {
            filter.initialAutoselectEnableOnce = false;
            filter.autoselect = false;
          }
        }
      });
      commit("setFilters", state.filters);
    })
    .catch((error) => {
      console.log("error fetching filter", error);
      dispatch(
        "showMessage",
        {
          title: "Error while fetching filter data",
          message: "" + error,
        },
        { root: true }
      );
    })
    .then(() => {
      commit("setLoadingFilters", false);
    });
}

export default bootstrap({
  namespaced: true,

  state: {
    loadingTasks: false,
    loadingFilters: false,
    pagination: {
      descending: false,
      page: 1,
      rowsPerPage: 50,
      sortBy: null,
    },
    totalTasks: 0,
    filters: {
      orderSubjectCustomer: {
        browserKey: "oc",
        autoselect: false,
        parameter: "orderSubject.customer",
        responseKey: "orderSubjectCustomer",
        values: [],
        selected: [""],
        selectionCache: {},
      },
      orderSubjectName: {
        browserKey: "on",
        autoselect: false,
        parameter: "orderSubject.name",
        responseKey: "orderSubjectName",
        values: [],
        selected: [],
        selectionCache: {},
      },
      leadArticleMediaName: {
        browserKey: "mn",
        autoselect: true,
        parameter: "taskGroup.leadArticleMediaName",
        responseKey: "leadArticleMediaName",
        values: [],
        selected: [],
        selectionCache: {},
      },
      priority: {
        browserKey: "p",
        autoselect: true,
        parameter: "taskGroup.priority",
        responseKey: "priority",
        values: [],
        selected: [],
        selectionCache: {},
      },
      type: {
        browserKey: "t",
        autoselect: true,
        parameter: "type",
        responseKey: "type",
        values: [],
        selected: [],
        selectionCache: {},
      },
      language: {
        browserKey: "l",
        autoselect: true,
        parameter: "language",
        responseKey: "language",
        values: [],
        selected: [],
        selectionCache: {},
      },
      status: {
        browserKey: "s",
        parameter: "status",
        responseKey: "status",
        values: ["open", "review", "ready for review", "write", "done", "deleted"],
        selected: ["open", "review", "ready for review", "write"],
        selectionCache: {
          // open: true,
          // review: true,
          // 'ready for review': true,
          // write: true,
          // done: false
        },
      },
      author: {
        browserKey: "a",
        autoselect: true,
        parameter: "author",
        responseKey: "author",
        values: [],
        selected: [],
        selectionCache: {},
      },
      reviewer: {
        browserKey: "r",
        autoselect: true,
        parameter: "reviewer",
        responseKey: "reviewer",
        values: [],
        selected: [],
        selectionCache: {},
      },
    },
    tasks: [],
  },

  actions: {
    fetchTasks: _.debounce(queryTasks, 1000, { leading: true, trailing: true }),

    fetchFilters: _.debounce(queryFilters, 1000, {
      leading: true,
      trailing: true,
    }),
  },
});
