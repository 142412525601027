"use strict";

/**
 * Based on https://github.com/kvaes/TasmanianTraders-MSAL-AdvancedVueJsExample
 */

import { connectToFirebase } from "@/firebaseConnect";
import * as Msal from "msal";
import axios from "../axios";

const config = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    authority: process.env.VUE_APP_MSAL_AUTHORITY,
    redirectUri: window.location.origin + "/auth/login",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

const scopes = [config.auth.clientId];

class AuthService {
  constructor() {
    this.app = new Msal.UserAgentApplication(config);
    this.app.handleRedirectCallback(() => {
      console.log("Called login redirect callback");
    });
  }

  // Core Functionality
  async loginPopup() {
    try {
      await this.app.loginPopup({ scopes });
    } catch (error) {
      console.log("error while authenticating", error);
      throw error;
    }

    const user = this.getUser();
    if (user) {
      // check ad roles
      try {
        const rolesAllowed = (await axios.get("/roles")).data;
        if (rolesAllowed) {
          if (!user.idToken.roles || !user.idToken.roles.find((r) => rolesAllowed.includes(r))) {
            this.app.clearCache();
            throw new Error(`User ${user.name} is not in one of roles [${rolesAllowed}]`);
          }
        }
      } catch (error) {
        console.log("error while authenticating and getting roles", error);
        throw error;
      }

      await this.getAccessToken();
      connectToFirebase();
      return user;
    } else {
      return null;
    }
  }

  logout() {
    this.app.logout();
  }

  // Graph Related
  async getAccessToken() {
    if (process.env.VUE_APP_CYPRESS_AUTH_STUB === "true") {
      return Promise.resolve("test");
    }

    try {
      const response = await this.app.acquireTokenSilent({ scopes });
      return response.idToken.rawIdToken;
    } catch (err) {
      const response = await this.app.acquireTokenPopup({ scopes });
      return response.idToken.rawIdToken;
    }
  }

  // Utility
  getUser() {
    if (process.env.VUE_APP_CYPRESS_AUTH_STUB === "true") {
      return {
        displayableId: "test@unicepta.com",
      };
    }

    const account = this.app.getAccount();
    if (!account) {
      return;
    }
    return {
      displayableId: account.userName,
      name: account.name,
      idToken: account.idToken,
    };
  }
}

export default new AuthService();
