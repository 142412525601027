<template>
  <v-card v-if="taskGroup" class="text-xs-left">
    <v-card-title primary-title>
      <v-layout row nowrap align-start fill-height justify-start class="mx-0">
        <div>
          <h2 class="headline mb-0">
            {{ taskGroup.orderSubject.customer }}, {{ taskGroup.orderSubject.name }}, {{ taskGroup.subjectArea }}
          </h2>
          <h3 class="subheading">
            {{ taskGroup.leadArticleMediaName }},
            {{ taskGroup.leadArticlePublishedAt | date }}
          </h3>
          <div>
            {{ taskGroup.leadArticleHeadline }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <app-cloud
          :state="cloudState"
          class="ml-3 mt-1"
          @refresh="onRefreshTaskGroupInfo"
          @error="onErrorTaskGroupInfo"
          @checkTimeout="onCheckTimeoutTaskGroupInfo"
        >
        </app-cloud>
      </v-layout>
    </v-card-title>

    <v-card-text>
      <v-textarea
        label="Comments"
        autoGrow
        :loading="loading"
        rows="1"
        v-model="taskGroupEdit.comment"
        @input="onCommentInput"
      >
      </v-textarea>
      <!-- <v-btn flat
                   @click='saveComment'>Save</v-btn> -->

      <template v-if="showInstructions">
        <div class="caption text--secondary mt-4">Instructions</div>
        {{ taskGroup.orderSubject.instructions }}
      </template>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn flat color="primary" @click="setShowInstructions(!showInstructions)">
        <span>{{ showInstructions ? "Hide Instructions" : "Show Instructions" }}</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-tooltip bottom nudge-right open-delay="2000">
        <v-btn icon slot="activator" :href="taskGroup.orderSubject.wikiLink" target="_blank">
          <v-icon color="secondary">mdi-wikipedia</v-icon>
        </v-btn>
        <span>Open Wiki link in a new browser tab</span>
      </v-tooltip>
    </v-card-actions>

    <app-comment-error
      :show="showCommentErrorOptions"
      @dismissed="showCommentErrorOptions = false"
      @refresh="onRefreshTaskGroupInfo"
    >
    </app-comment-error>
  </v-card>
</template>

<script>
import Cloud from "@/components/shared/Cloud.vue";
import CommentErrorDialog from "@/components/taskGroup/CommentErrorDialog.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  props: ["taskGroup", "taskGroupEdit", "loading"],

  components: {
    appCloud: Cloud,
    appCommentError: CommentErrorDialog,
  },

  data() {
    return {
      showCommentErrorOptions: false,
    };
  },

  computed: {
    ...mapGetters("taskGroup", ["cloudState", "showInstructions", "lastCommentError"]),
  },

  methods: {
    ...mapMutations("taskGroup", ["setShowInstructions", "setCloudState"]),
    ...mapActions("taskGroup", ["refreshComment", "updateComment", "commentInputChanged"]),

    saveComment() {
      // this.updateComment()
      switch (this.cloudState) {
        case "refresh":
          this.setCloudState("error");
          break;
        case "error":
          this.setCloudState("progress");
          break;
        case "progress":
          this.setCloudState("check");
          break;
        case "check":
          this.setCloudState(null);
          break;
        default:
          this.setCloudState("refresh");
      }
    },

    onCommentInput() {
      this.commentInputChanged();
    },

    onRefreshTaskGroupInfo() {
      this.refreshComment();
    },

    onCheckTimeoutTaskGroupInfo() {
      this.setCloudState("refresh");
    },

    onErrorTaskGroupInfo() {
      if (this.lastCommentError && this.lastCommentError.response && this.lastCommentError.response.status === 409) {
        this.showCommentErrorOptions = true;
      }
    },
  },
};
</script>
