//TODO: Get rid of this validity check once we retire Shape.
function isValidDate(date) {
  // If the date is not valid getTime() will return NaN.
  // NaN is never equal to itself, so this checks for a valid date.
  return date.getTime() === date.getTime();
}

export default (value) => {
  const date = new Date(value);
  return isValidDate(date)
    ? date.toLocaleString(["de", "en-US"], {
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
    : value;
};
