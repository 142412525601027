<template>
  <div>
    <v-layout row align-center style="position: relative" class="mx-0">
      <div
        v-if="label"
        class="subheading text-no-wrap v-label"
        :class="{
          'primary--text': focused,
          'text--secondary': !focused,
          'v-label-active': focused || characterCount > 0,
        }"
      >
        {{ labelWithCounter }}
      </div>

      <v-spacer></v-spacer>
      <v-btn-toggle multiple :value="false" v-model="formatToggles">
        <v-btn flat small value="link" @focus="onFocus" @blur="onBlur" @click="addLink($event)">
          <v-icon small>mdi-link-variant</v-icon>
        </v-btn>
        <v-btn flat small :disabled="disabled" value="bold" @focus="onFocus" @blur="onBlur" @click="formatBold">
          <v-icon small>mdi-format-bold</v-icon>
        </v-btn>
        <v-btn flat small :disabled="disabled" value="italic" @focus="onFocus" @blur="onBlur" @click="formatItalic">
          <v-icon small>mdi-format-italic</v-icon>
        </v-btn>
        <v-btn flat small :disabled="disabled" value="list" @focus="onFocus" @blur="onBlur" @click="formatList">
          <v-icon small>mdi-format-list-bulleted</v-icon>
        </v-btn>
        <v-menu top nudge-top :disabled="disabled" v-model="showColorDialog" :close-on-content-click="false">
          <v-btn flat :disabled="disabled" slot="activator" value="color" @focus="onFocus" @blur="onBlur" small>
            <v-icon small>format_color_text</v-icon>
          </v-btn>
          <v-card>
            <color-picker :value="color" :preset-colors="presetColors" @input="onInputColorPicker($event)" />
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn flat @click="showColorDialog = false"> Cancel </v-btn>
              <v-btn flat color="primary" @click="formatColor"> Ok </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-btn-toggle>
    </v-layout>

    <quill-editor
      :class="{ focused: focused, loading: loading }"
      ref="quillEditor"
      :disabled="disabled"
      :options="editorOptions"
      :value="value"
      @input="$emit('input', $event)"
      @change="onEditorChange($event)"
      @focus="onEditorFocus"
      @blur="onEditorBlur"
    >
    </quill-editor>
    <v-progress-linear v-if="loading" :active="loading" :indeterminate="true" height="2" class="my-0">
    </v-progress-linear>
  </div>
</template>

<script>
// import Quill from 'quill'
import { quillEditor } from "vue-quill-editor";
import { Sketch } from "vue-color";
import * as util from "@/utilities/utilities";

import "quill/dist/quill.core.css";
// import 'quill/dist/quill.snow.css'

export default {
  props: ["label", "value", "counter", "loading", "autofocus", "disabled"],

  components: {
    quillEditor,
    "color-picker": Sketch,
  },

  data() {
    return {
      formatToggles: [],
      showColorDialog: false,
      color: "#FF0000",
      colorTemp: null,
      showBackgroundDialog: false,
      background: "#FF0000",
      backgroundTemp: null,
      presetColors: [
        "#FF0000",
        "#FF9900",
        "#00FF00",
        "#417505",
        "#7ED321",
        "#F8E71C",
        "#8B572A",
        "#BD10E0",
        "#9013FE",
        "#4A90E2",
        "#50E3C2",
        "#000000",
        "#4A4A4A",
        "#9B9B9B",
        "#FFFFFF",
        "rgba(0,0,0,0)",
      ],
      characterCount: 0,
      focused: false,
      editorOptions: {
        modules: {
          // toolbar: [
          //     ['bold', 'italic'],
          //     [{ list: 'bullet' }],
          //     [{ color: ['#0F0'] }, { background: [] }]
          // ]
          toolbar: null, // '#toolbar_' + this.id
        },
        placeholder: null,
        // theme: 'snow'
      },
      // editorFormat: null
    };
  },

  computed: {
    editor() {
      return this.$refs.quillEditor.quill;
    },

    labelWithCounter() {
      if (this.counter === "" || this.counter) {
        return `${this.label} (${this.characterCount})`;
      } else {
        return this.label;
      }
    },
  },

  methods: {
    onEditorChange(quill) {
      this.characterCount = util.getHtmlCharacterCount(quill.text);
    },

    onInputColorPicker(value) {
      // console.log( 'ColorTemp', value )
      this.colorTemp = value.hex8;

      // this.focus = true
    },

    addLink(value) {
      if (value) {
        var href = prompt("Enter the URL");
        this.editor.format("link", href);
      } else {
        this.editor.format("link", false);
      }
    },

    onFocus() {
      // console.log( 'Focus', event )
      this.focused = true;
    },

    onBlur() {
      // console.log( 'Blur', event )
      this.focused = false;
    },

    onEditorFocus() {
      this.focused = true;

      // onFocus is also called for Quills selection-change event
      // console.log( 'Focus', quill.getFormat() )
      this.updateFormatToggles();
    },

    onEditorBlur() {
      this.focused = false;
      // console.log( 'Blur' ) // do not call quill.getFormat() -> causes editor to get focus again!!!
    },

    updateFormatToggles() {
      this.formatToggles = Object.keys(this.editor.getFormat());
    },

    formatBold() {
      this.editor.format("bold", !this.editor.getFormat().bold);
    },

    formatItalic() {
      this.editor.format("italic", !this.editor.getFormat().italic);
    },

    formatList() {
      this.editor.format("list", this.editor.getFormat().list ? null : "bullet");
    },

    formatColor() {
      console.log("formatColor", this.colorTemp);
      if (this.colorTemp) {
        this.color = this.colorTemp;
        this.colorTemp = null;
      }
      this.editor.format("color", this.color);
      this.showColorDialog = false;
      // this.$refs.quillEditor.focus()
    },
    focus() {
      // console.log( 'TextQuillFokus' )
      if (!this.editor.hasFocus()) {
        this.editor.setSelection(this.editor.getLength(), 0, "user");
        if (this.editor.hasFocus()) {
          this.onEditorFocus(this.editor);
        }
      }
    },
  },

  mounted() {
    this.characterCount = util.getHtmlCharacterCount(this.editor.getText());

    if (this.autofocus === "" || this.autofocus) {
      this.$nextTick(() => {
        this.focus();
      });
    }

    // console.log( 'Mounted TEXT EDITOR' )
    this.$emit("mounted");
  },
};
</script>

<style lang="scss" scoped>
.v-label {
  position: absolute;
  top: 35px;
  left: 0;
  right: auto;
  min-height: 8px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: top left;
  -webkit-transform-origin: top left;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

  &.v-label-active {
    max-width: 133%;
    -webkit-transform: translateY(-27px) scale(0.75);
    transform: translateY(-27px) scale(0.75);
  }
}
</style>

<style lang="scss">
.ql-container.ql-snow {
  border: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
}

.ql-editor {
  line-height: 18px;
  padding: 7px 0 8px;
}

.ql-toolbar.ql-snow {
  border: none;
  padding: 0;
}

.ql-container:before {
  border-color: rgba(0, 0, 0, 0.42);
  border-style: solid;
  border-width: thin 0 0 0;
}

.ql-container:hover:before {
  border-color: rgba(0, 0, 0, 0.87);
  border-style: solid;
  border-width: thin 0 0 0;
}

.ql-container:after {
  border-color: currentColor;
  border-style: solid;
  border-width: thin 0 thin 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.focused > .ql-container:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  color: var(--v-primary-base);
}

.ql-container::before,
.ql-container::after {
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

.loading > .ql-container:before,
.loading > .ql-container:after {
  content: none;
}

.vc-sketch {
  box-shadow: none !important;
}
</style>
