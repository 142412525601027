"use strict";

import Mustache from "mustache";

const VALID_HTML_TAGS = new Set(["p", "b", "i", "u", "del", "strike", "span", "div", "br"]);

export function clearMappingResult(mappingResult) {
  let clearResult = {};

  for (const type in mappingResult) {
    let shapeDataObjects = mappingResult[type];
    clearResult[type] = [];

    for (const data of shapeDataObjects) {
      if (data.content && data.content !== "") {
        let clearedData = data.content.replace(/<[^>]*>?/gm, "");
        if (clearedData !== "") {
          clearResult[type].push(data);
        }
      }
    }
  }

  return clearResult;
}

export function applyMappings(orderSubject, leadArticleLanguage, tasks) {
  // fill data object with all possible values from tasks
  const mappingSources = {};
  for (let task of tasks) {
    if (task.type === "snippet") {
      mappingSources.snippet = task.snippet;
    } else {
      if (task.showHeadline) {
        mappingSources["headline_" + task.language] = task.headline;
      }
      if (task.showAbstract) {
        mappingSources["abstract_" + task.language] = task.abstract;
      }
    }
  }

  // sort shapemappings by order
  const shapeMappings = orderSubject.shapeMappings
    .filter((s) => s.isActive)
    .filter((s) => {
      let regex = new RegExp(`^${s.articleLanguage.replace("*", ".*")}`);
      return leadArticleLanguage.match(regex);
    })
    .sort((s1, s2) => s1.order - s2.order);

  // console.log( 'ShapeMappings', shapeMappings.length )

  const editorialProduct = {
    Abstracts: [],
    CustomHeadlines: [],
    Snippets: [],
  };

  for (let mapping of shapeMappings) {
    if (mapping.unidocField === "Snippet") {
      let snippet = null;
      if (editorialProduct.Snippets.length === 0) {
        snippet = {
          content: "",
        };
        editorialProduct.Snippets.push(snippet);
      } else {
        snippet = editorialProduct.Snippets[0];
      }

      appendMapping(mapping, mappingSources, snippet);
    } else if (mapping.unidocField === "Custom Headline") {
      let headline = editorialProduct.CustomHeadlines[0];
      if (!headline) {
        headline = {
          content: "",
          language: null,
        };
        editorialProduct.CustomHeadlines.push(headline);
      }

      appendMapping(mapping, mappingSources, headline);
    } else if (mapping.unidocField.startsWith("Abstract")) {
      const lang = mapping.unidocField.split(" ")[1];
      let abstract = editorialProduct.Abstracts.find((a) => a.language === lang);
      if (!abstract) {
        abstract = {
          content: "",
          language: lang,
        };
        editorialProduct.Abstracts.push(abstract);
      }

      appendMapping(mapping, mappingSources, abstract);
    } else {
      throw new Error("Unknown unidoc field type " + mapping.unidocField);
    }
  }

  // remove unnecessary data
  Object.values(editorialProduct).forEach((unidocs) =>
    unidocs.forEach((unidoc) => delete unidoc.matchedLanguageFilter)
  );

  // console.log( 'Product', JSON.stringify( editorialProduct, null, 2 ) )

  return editorialProduct;
}

function appendMapping(mapping, mappingSources, contentHolder) {
  if (
    contentHolder.matchedLanguageFilter !== undefined &&
    contentHolder.matchedLanguageFilter !== mapping.articleLanguage
  ) {
    // another filter not equal to this one has matched already -> ignore this one
    return;
  }

  const append = Mustache.render(mapping.mapping, mappingSources);

  if (append.length > 0) {
    if (contentHolder.content !== null && contentHolder.content.length > 0) {
      contentHolder.content += "<br>";
    }
    contentHolder.content += append;
  }

  // store mapping language filter matched so that only same filters are considered hereafter
  contentHolder.matchedLanguageFilter = mapping.articleLanguage;
}

function hasInvalidChild(doc) {
  for (let i = 0; i < doc.childNodes.length; i += 1) {
    const child = doc.childNodes[i];
    const nodeName = child.nodeName;
    if (child.nodeType === 1 && !VALID_HTML_TAGS.has(nodeName.toLowerCase())) {
      return `Invalid tag <${nodeName}>`;
    } else {
      const result = hasInvalidChild(doc.childNodes[i]);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export function isInvalidHtml(html) {
  // console.log( 'Validate HTML', html )
  if (!html) {
    return false;
  }
  const doc = new DOMParser().parseFromString(
    `<root>${html.replaceAll("<br>", "").replaceAll("<br/>", "")}</root>`,
    "text/xml"
  );
  const errorNode = doc.querySelector("parsererror");
  if (errorNode) {
    return true;
  } else {
    const inLowerCase = html.toLowerCase();
    const invalidTopLevelTags = ["<html>", "<body>", "<head>"];
    for (const invalidTag of invalidTopLevelTags) {
      if (inLowerCase.includes(invalidTag)) {
        return true;
      }
    }
    const dom = new DOMParser().parseFromString(html, "text/html");
    const result = hasInvalidChild(dom.body);
    if (result) {
      return true;
    }
  }
  return false;
}

/*
 * A token is an array with at least 4 elements. The first element is the
 * mustache symbol that was used inside the tag, e.g. "#" or "&". If the tag
 * did not contain a symbol (i.e. {{myValue}}) this element is "name". For
 * all text that appears outside a symbol this element is "text".
 *
 * The second element of a token is its "value". For mustache tags this is
 * whatever else was inside the tag besides the opening symbol. For text tokens
 * this is the text itself.
 *
 * The third and fourth elements of the token are the start and end indices,
 * respectively, of the token in the original template.
 *
 * Tokens that are the root node of a subtree contain two more elements: 1) an
 * array of tokens in the subtree and 2) the index in the original template at
 * which the closing tag for that section begins.
 */
export function containsInvalidPlaceholders(mapping) {
  return new Promise((resolve) => {
    try {
      Mustache.parse(mapping);
      resolve(false);
    } catch (err) {
      // console.log( JSON.stringify( err, null, 2 ) )
      resolve(err.message);
    }
  });
}

export async function isInvalid(mapping) {
  const resHtml = isInvalidHtml(mapping);
  if (resHtml) {
    return resHtml;
  }
  return containsInvalidPlaceholders(mapping);
}
