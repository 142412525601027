<template>
  <div style="position: relative">
    <app-fade-transition mode="out-in">
      <v-icon v-if="state === 'refresh'" key="refresh" @click="$emit('refresh')">mdi-refresh</v-icon>
      <div v-else-if="state === 'progress'" key="progress">
        <v-icon>mdi-cloud</v-icon>
        <v-progress-circular
          small
          size="10"
          width="2"
          style="top: 7px; position: absolute; left: 7px"
          color="white"
          indeterminate
        >
        </v-progress-circular>
      </div>
      <v-icon v-else-if="state === 'check'" key="check">mdi-cloud-check</v-icon>
      <v-icon v-else-if="state === 'error'" key="error" color="error" @click="$emit('error')">mdi-cloud-alert</v-icon>
    </app-fade-transition>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      validator() {
        return ["refresh", "error", "progress", "check"];
      },
    },
  },

  data() {
    return {
      timeoutId: null,
    };
  },

  watch: {
    state() {
      this.handleCheck();
    },
  },

  methods: {
    handleCheck() {
      if (this.state === "check") {
        setTimeout(() => {
          this.timeoutId = null;
          if (this.state === "check") {
            this.$emit("checkTimeout");
          }
        }, 5000);
      } else {
        if (this.timeoutId !== null) {
          clearTimeout(this.timeoutId);
        }
      }
    },
  },

  created() {
    this.handleCheck();
  },
};
</script>
