"use strict";

import { bootstrap } from "@/utilities/vuex";
import axios from "@/axios";
import { showMessage } from "@/utilities/utilities";

export default bootstrap({
  namespaced: true,

  state: {
    loading: false,
    loadError: false,
    taskGroup: null,
    articles: [],
    selectedArticle: null,
    selectedAbstract: null,
    selectedTab: 0,
  },

  actions: {
    async init({ commit, dispatch }, taskGroupId) {
      commit("setLoading", true);
      try {
        // check if this taskGroup is already in local storage
        const taskGroup = JSON.parse(window.localStorage.getItem("taskGroup"));
        if (taskGroup && `${taskGroup.id}` === `${taskGroupId}`) {
          console.log("success getting task group from local storage", taskGroup);
          commit("setTaskGroup", taskGroup);
        } else {
          const resultTaskGroup = await axios.get("/task-groups/withchildren/" + taskGroupId);
          console.log("success fetching task group", resultTaskGroup.data);
          commit("setTaskGroup", resultTaskGroup.data);
        }

        window.localStorage.removeItem("taskGroup");
        commit("setLoadError", false);

        await dispatch("fetchArticles");
      } catch (error) {
        commit("setLoadError", error);
        console.log("error fetching task group", error);
        showMessage(dispatch, "Error while fetching task group", "" + error);
      } finally {
        commit("setLoading", false);
      }
    },

    async fetchArticles({ getters, commit, dispatch }) {
      commit("setLoading", true);
      const taskGroup = getters.taskGroup;
      const articleIds = [
        taskGroup.shapeLeadArticleId,
        ...taskGroup.supplementalArticleRefs.map((articleRef) => articleRef.shapeArticleId),
      ];
      const requestsArticles = articleIds.map((articleId) => {
        const inboxArticle = taskGroup.articles
          ? taskGroup.articles.find((it) => it.recordId === articleId)
          : undefined;
        if (inboxArticle) {
          return Promise.resolve({ ...inboxArticle, imagesUrls: (inboxArticle.images || []).map((it) => it.url) });
        }
        return axios.get("/documents/" + articleId).then((res) => res.data);
      });
      const requestsAbstractsHeadlines = articleIds.map((articleId) =>
        axios.get("/tasks-abstracts/task-groups/" + taskGroup.id + "/articles/" + articleId)
      );
      for (let i in requestsArticles) {
        let reference = taskGroup.supplementalArticleRefs.filter(
          (articleRef) => articleRef.shapeArticleId === articleIds[i]
        );

        const articleContainer = {
          id: articleIds[i],
          refEditorialProducts: [],
        };

        if (reference !== []) {
          articleContainer.reference = reference[0];
        }

        getters.articles.push(articleContainer);

        try {
          articleContainer.article = await requestsArticles[i];
          articleContainer.article.content = articleContainer.article.content.replace(/{para}/gi, "<p>");
          articleContainer.article.content = articleContainer.article.content.replace(/{\/para}/gi, "</p>");
        } catch (error) {
          articleContainer.errorArticle = error;
          showMessage(dispatch, "Error while fetching article", "" + error);
        }

        try {
          const resAbstractHeadline = await requestsAbstractsHeadlines[i];
          articleContainer.refEditorialProducts = resAbstractHeadline.data;
        } catch (error) {
          articleContainer.errorAbstracts = error;
          showMessage(dispatch, "Error while fetching abstract", "" + error);
        }

        commit("setArticles", getters.articles);
      }

      commit("setLoading", false);
    },
  },
});
