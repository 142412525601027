export default {
  props: ["relatedGroups"],
  methods: {
    getRelatedData: function () {
      var formattedGroups = [];
      var groups = this.relatedGroups;

      groups.forEach(function (group) {
        let formattedTask = {};

        formattedTask.id = group.id;
        formattedTask.subjectName = group.orderSubject.name;
        formattedTask.creator = group.creator;

        formattedTask.tasksStatuses = [];

        group.tasks.forEach(function (task) {
          if (task.type === "abstract" || task.showAbstract) {
            let taskWithStatus = {
              type: task.type,
              status: task.status,
              language: task.language,
            };

            formattedTask.tasksStatuses.push(taskWithStatus);
          }
        });

        formattedGroups.push(formattedTask);
      });
      return formattedGroups;
    },

    viewItem: function (taskGroup) {
      let routeData = this.$router.resolve("/task-groups/" + taskGroup.id);
      window.open(routeData.href, "_blank");
    },
  },

  data: function () {
    return {
      groups: this.relatedGroups,
      showRelated: true,
      headers: [
        {
          text: "Name",
          sortable: false,
          value: "name",
        },
        {
          text: "Creator",
          sortable: false,
          value: "creator",
        },
        {
          text: "Abstracts Status",
          sortable: false,
          value: "staus",
        },
      ],
    };
  },
};
