<template>
  <app-fade-transition mode="out-in">
    <v-container v-if="loadingOrderSubject" key="loadingOrderSubject" fill-height class="pr-0">
      <v-layout align-center justify-center>
        <v-flex xs12 sm6 md4 lg3 xl2>
          Loading Order Subject
          <v-progress-linear indeterminate></v-progress-linear>
        </v-flex>
      </v-layout>
    </v-container>

    <v-card v-else>
      <template v-if="orderSubject">
        <v-card-title class="deep-purple white--text py-5 px-4" primary-title>
          <v-layout row wrap>
            <h2 class="headline">{{ orderSubject.customer }}, {{ orderSubject.name }}</h2>
          </v-layout>
        </v-card-title>
        <!-- <v-container fluid > -->

        <!-- {{(orderSubject && orderSubject.id !== undefined && orderSubject.id !== null) ? 'In Edifix' : 'Only Shape'}}
                    /
                    {{hasUnsavedChanges ? 'Edited' : 'Unchanged'}} -->

        <v-card-text class="text-xs-left">
          <v-form ref="form">
            <v-layout row align-start class="mt-2"> </v-layout>
            <v-layout row wrap>
              <v-flex xs12 lg6>
                <v-textarea
                  label="Instructions"
                  autoGrow
                  rows="1"
                  prepend-icon="info"
                  :disabled="saving"
                  v-model="orderSubject.instructions"
                >
                </v-textarea>
              </v-flex>
              <v-flex xs12 lg6>
                <v-textarea
                  label="Wiki Link"
                  autoGrow
                  rows="1"
                  prepend-icon="mdi-wikipedia"
                  :disabled="saving"
                  v-model="orderSubject.wikiLink"
                >
                </v-textarea>
              </v-flex>
            </v-layout>

            <div class="caption text--secondary mt-3">Task Templates</div>
            <v-layout row>
              <v-flex xs12>
                <app-task-template-table
                  ref="taskTemplatesTable"
                  :taskTemplates="orderSubject.taskTemplates"
                  :disabled="saving"
                >
                </app-task-template-table>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="mx-1 mt-1">
              <v-checkbox
                :disabled="saving"
                v-model="orderSubject.useSnippets"
                label="Preselect Snippet Field"
              ></v-checkbox>
              <v-spacer></v-spacer>
              <v-textarea
                label="Label for Snippetfield"
                autoGrow
                rows="1"
                prepend-icon="local_offer"
                :disabled="saving"
                v-model="orderSubject.snippetLabel"
              >
              </v-textarea>
            </v-layout>

            <div class="caption text--secondary mt-4">Shape Mappings</div>
            <v-layout row>
              <v-flex xs12>
                <app-shape-mapping-table
                  ref="shapeMappingTable"
                  :shapeMappings="orderSubject.shapeMappings"
                  :mappingPlaceholders="mappingPlaceholders"
                  :disabled="saving"
                >
                </app-shape-mapping-table>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-3">
          <v-btn
            @click="onClickSave"
            flat
            color="primary"
            :loading="saving"
            :disabled="!hasUnsavedChanges || loadingOrderSubject || saving"
            >Save</v-btn
          >
          <v-btn @click="onClickValidation" flat color="primary" :disabled="loadingOrderSubject || saving"
            >Validate</v-btn
          >
          <v-btn @click="onClickCancel" flat :disabled="!hasUnsavedChanges || loadingOrderSubject || saving"
            >Cancel</v-btn
          >
        </v-card-actions>

        <app-confirm-dialog
          :show="showConfirmCancel"
          headline="Discard Changes?"
          text="Do you really want to discard all edits you have made?"
          textOk="Yes"
          textCancel="No"
          @dismissed="showConfirmCancel = false"
          @ok="onOkCancel"
        >
        </app-confirm-dialog>
      </template>
    </v-card>
  </app-fade-transition>
</template>

<script>
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import TaskTemplateTable from "@/components/orderSubjects/TaskTemplateTable.vue";
import ShapeMappingTable from "@/components/orderSubjects/ShapeMappingTable.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["orderSubject", "hasUnsavedChanges", "autofocus"],

  components: {
    appTaskTemplateTable: TaskTemplateTable,
    appShapeMappingTable: ShapeMappingTable,
    appConfirmDialog: ConfirmDialog,
  },

  data() {
    return {
      showConfirmCancel: false,
    };
  },

  computed: {
    ...mapGetters("orderSubjects", ["loadingOrderSubject", "saving"]),

    mappingPlaceholders() {
      const placeHolders = {};
      if (this.orderSubject.useSnippets) {
        placeHolders.snippet = null;
      }
      this.orderSubject.taskTemplates.forEach((t) => {
        if (t.language) {
          if (t.showHeadline) {
            placeHolders["headline_" + t.language] = null;
          }
          if (t.showAbstract) {
            placeHolders["abstract_" + t.language] = null;
          }
        }
      });
      return Object.keys(placeHolders);
    },
  },

  methods: {
    ...mapActions(["showMessage"]),
    ...mapActions("orderSubjects", ["saveOrderSubject", "cancelOrderSubject"]),

    async onClickSave() {
      if (this.validate()) {
        try {
          await this.saveOrderSubject();
          this.resetValidation();
        } catch (error) {
          // already processed ignore
        }
      } else {
        this.showMessage({
          title: "Invalid configuration",
          message: "One or more configurations are not valid! Please review marked entries.",
        });
      }
    },

    async onClickCancel() {
      this.showConfirmCancel = true;
    },

    async onOkCancel() {
      await this.cancelOrderSubject();
      this.resetValidation();
    },

    validate() {
      return this.$refs.shapeMappingTable.validate() && this.$refs.taskTemplatesTable.validate();
    },

    resetValidation() {
      this.$refs.shapeMappingTable.resetValidation();
      this.$refs.taskTemplatesTable.resetValidation();
    },

    onClickValidation() {
      console.log("Validation", this.$refs.shapeMappingTable.validate());

      // if ( this.$refs.form.validate() ) {
      if (this.validate()) {
        this.showMessage({
          title: "Valid",
          message: "All configurations are valid",
          color: "success",
        });
      } else {
        this.showMessage({
          title: "Invalid configuration",
          message: "One or more configurations are not valid! Please review marked entries.",
        });
      }
    },
  },
};
</script>
