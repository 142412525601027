<template>
  <div>
    <v-data-table
      id="table-shape-mappings"
      :headers="headers"
      :items="sortedMappings"
      item-key="id"
      :hide-actions="true"
      no-data-text="No shape mappings have been added yet"
      class="elevation-1"
    >
      <template slot="headerCell" slot-scope="props">
        <v-tooltip bottom>
          <v-btn
            v-if="props.header.text === 'Action'"
            icon
            color="primary"
            small
            :disabled="disabled"
            @click="onAddMapping"
            slot="activator"
          >
            <v-icon>add</v-icon>
          </v-btn>
          <span v-else slot="activator">
            {{ props.header.text }}
          </span>
          <span>
            {{ props.header.tooltip }}
          </span>
        </v-tooltip>
      </template>

      <template slot="items" slot-scope="props">
        <tr>
          <td>
            <v-checkbox :disabled="disabled" v-model="props.item.$model.isActive"> </v-checkbox>
          </td>
          <td class="text-xs-left">
            <v-select
              :items="unidocFieldItems"
              class="text-no-wrap not-wide"
              solo
              dense
              flat
              :disabled="disabled"
              v-model="props.item.$model.unidocField"
              @change="props.item.unidocField.$touch()"
              :error-messages="errorsUnidocField(props.item.unidocField)"
            >
            </v-select>
          </td>
          <td class="text-xs-left">
            <v-text-field
              solo
              flat
              :disabled="disabled"
              @change="props.item.articleLanguage.$touch()"
              v-model="props.item.$model.articleLanguage"
              :error-messages="errorsArticleLanguage(props.item.articleLanguage)"
            >
            </v-text-field>
          </td>
          <td class="text-xs-left">
            <v-textarea
              solo
              flat
              :disabled="disabled"
              auto-grow
              :rows="1"
              v-model="props.item.$model.mapping"
              @blur="props.item.mapping.$touch()"
              :error-messages="errorsMapping(props.item.mapping)"
            >
            </v-textarea>
          </td>
          <td class="justify-center px-0">
            <v-layout row fill-height class="justify-center" no-wrap>
              <v-btn
                v-show="props.item.$model.order > 0"
                icon
                :disabled="disabled"
                class="btn-order-up"
                @click.prevent.stop="onOrderUp(props.item)"
              >
                <v-icon color="secondary" large> mdi-menu-up </v-icon>
              </v-btn>
              <v-btn
                v-show="props.item.$model.order < shapeMappings.length - 1"
                icon
                :disabled="disabled"
                class="btn-order-down"
                @click.prevent.stop="onOrderDown(props.item)"
              >
                <v-icon color="secondary" large> mdi-menu-down </v-icon>
              </v-btn>
            </v-layout>
          </td>
          <td class="px-0 text-xs-center">
            <v-layout row fill-height class="justify-center" no-wrap>
              <v-btn icon :disabled="disabled" @click.prevent.stop="onDeleteMapping(props.item.$model)">
                <v-icon color="secondary"> mdi-trash-can-outline </v-icon>
              </v-btn>
            </v-layout>
          </td>
        </tr>
      </template>
    </v-data-table>
    <app-confirm-dialog
      :show="showConfirmDelete"
      headline="Delete Mapping?"
      :text="textConfirmDelete"
      textOk="Yes"
      textCancel="No"
      @dismissed="showConfirmDelete = false"
      @ok="onOkDelete"
      @cancel="onCancelDelete"
    >
    </app-confirm-dialog>
  </div>
</template>

<script>
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import { isInvalidHtml, containsInvalidPlaceholders } from "@/utilities/mappings";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import languageCodes from "@/utilities/lang_codes_iso639-1";

export default {
  props: ["shapeMappings", "disabled", "mappingPlaceholders"],

  components: {
    appConfirmDialog: ConfirmDialog,
  },

  mixins: [validationMixin],

  validations: {
    shapeMappings: {
      $each: {
        articleLanguage: {
          required,
          iso: (v) => v === "*" || languageCodes.includes(v),
        },

        unidocField: {
          required,
          known(v) {
            return this.unidocFieldItems.includes(v);
          },
        },

        mapping: {
          required,
          async html(v) {
            const invalid = isInvalidHtml(v);
            return !invalid;
          },
          async placeholder(v) {
            const invalid = Boolean(await containsInvalidPlaceholders(v));
            return !invalid;
          },
        },
      },
    },
  },

  data() {
    return {
      showConfirmDelete: false,
      mappingToDelete: null,

      headers: [
        {
          text: "Active",
          tooltip: "Only active mappings will be considered",
          align: "left",
          sortable: false,
          class: "string",
          value: "isActive",
        },
        {
          text: "Unidoc Field",
          tooltip: "SHAPE field to write into",
          align: "left",
          sortable: false,
          class: "string",
          value: "unidocField",
        },
        {
          text: "Article Language",
          tooltip: "Language the article was written in",
          align: "left",
          sortable: false,
          class: "string",
          value: "articleLanguage",
        },
        {
          text: "Mapping",
          tooltip: "Mapping template string",
          align: "left",
          sortable: false,
          class: "string",
          value: "mapping",
        },
        {
          text: "Order",
          tooltip: "Mappings will be evaluated from top to bottom",
          align: "left",
          sortable: false,
          class: "integer",
          value: "order",
        },
        {
          text: "Action",
          tooltip: "Add a new mapping",
          align: "left",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    unidocFieldItems() {
      return ["Custom Headline", "Snippet", "Abstract de", "Abstract en"];
    },

    textConfirmDelete() {
      return this.mappingToDelete
        ? `Do you really want to delete the mapping for unidoc field '${this.mappingToDelete.unidocField}' and article language '${this.mappingToDelete.articleLanguage}'?`
        : "";
    },

    sortedMappings() {
      // return [...this.shapeMappings].sort( ( s1, s2 ) => s1.order - s2.order )
      return Object.values(this.$v.shapeMappings.$each.$iter).sort((s1, s2) => s1.$model.order - s2.$model.order);
    },
  },

  methods: {
    onAddMapping() {
      this.shapeMappings.push({
        isActive: true,
        unidocField: null,
        articleLanguage: null,
        mapping: null,
        order: this.shapeMappings.length,
      });
    },

    onDeleteMapping(mapping) {
      this.mappingToDelete = mapping;
      console.log("onDeleteMapping", mapping);
      this.showConfirmDelete = true;
    },

    onOkDelete() {
      // delete entry
      this.shapeMappings.splice(this.shapeMappings.indexOf(this.mappingToDelete), 1);

      // adjust order field according to new order
      this.sortedMappings.forEach((m, i) => (m.$model.order = i));

      console.log("Ok delete");
    },

    onCancelDelete() {
      this.mappingToDelete = null;
      console.log("Cancel delete");
    },

    onOrderUp(mapping) {
      this.onOrder(mapping, true);
    },

    onOrderDown(mapping) {
      this.onOrder(mapping, false);
    },

    onOrder(mapping, up) {
      const sorted = [...this.sortedMappings];
      let index = sorted.indexOf(mapping);
      let swapIndex = null;
      if (up) {
        swapIndex = index === 0 ? sorted.length - 1 : index - 1;
      } else {
        swapIndex = index === sorted.length - 1 ? 0 : index + 1;
      }
      const mappingSwap = sorted[swapIndex];
      sorted[index] = mappingSwap;
      sorted[swapIndex] = mapping;
      sorted.forEach((m, i) => (m.$model.order = i));
    },

    errorsArticleLanguage(v) {
      if (!v.$dirty) return null;
      if (!v.required) return ["Value required"];
      if (!v.iso) {
        return ["No valid language code (e.g. de or wildcard *)"];
      }
      return null;
    },

    errorsUnidocField(v) {
      if (!v.$dirty) return null;
      if (!v.required) return ["Value required"];
      if (!v.known) return ["Unknown value"];
      return null;
    },

    errorsMapping(v) {
      if (v.$pending || !v.$dirty) return null;
      if (!v.required) return ["Value required"];
      if (!v.html) {
        return ["Invalid html"];
      }
      if (!v.placeholder) return ["Invalid placeholder"];
      return null;
    },

    validate() {
      this.$v.$touch();
      return !this.$v.shapeMappings.$invalid;
    },

    resetValidation() {
      this.$v.$reset();
    },
  },
};
</script>

<style lang="scss">
#table-shape-mappings div.v-input {
  position: relative;
  height: 100%;

  &.v-input--checkbox > div.v-input__control > div.v-input__slot {
    width: calc(100% + 20px);
  }

  &.error--text div.v-input__slot {
    border: 1px solid red;
    border-bottom: 0;
    border-radius: 2px 2px 0 0;
  }

  div.v-input__control {
    height: 100%;
    width: 100%; // for checkbox
    margin: 0px 0px 0px -10px;

    div.v-input__slot {
      font-size: 0.8em;
      color: var(--v-secondary-text-color) !important;
      padding: 0px 10px 0px 10px;
      margin: 0;
      width: calc(100% + 10px);
      // background-color: cyan;

      min-height: 48px; // for checkbox
      justify-content: center; // for checkbox

      div.v-input--selection-controls__input {
        margin: 0; // for checkbox
      }

      textarea {
        margin: 0;
        min-width: 10em;
      }
    }

    div.v-messages {
      // background-color: green;
      padding: 3px 10px;
      background-color: white;

      border: 1px solid red;
      border-top: 0;
      border-radius: 0 0 2px 2px;

      &:not(.error--text) {
        display: none;
      }
    }

    div.v-text-field__details {
      // display: none;
      margin: 0;
      padding: 0;
      max-width: calc(100% + 10px);
      width: calc(100% + 10px);
      // background-color: yellow;
    }
  }
}

#table-shape-mappings .not-wide div.v-input__slot {
  width: 100%;
}

#table-shape-mappings tr:not(:first-child):not(:last-child) {
  button.btn-order-up {
    // background-color: yellow;
    margin-right: 0;
    margin-left: -10px;
    padding: 0 0 0 0;
  }
  button.btn-order-down {
    // background-color: cyan;
    margin-right: -10px;
    margin-left: -10px;
    padding: 0 0 0 0;
  }
}
</style>
