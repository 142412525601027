var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"table-order-subjects","headers":_vm.headers,"items":_vm.orderSubjects,"loading":_vm.loadingOrderSubjects,"pagination":_vm.pagination,"totalItems":_vm.totalOrderSubjects,"no-data-text":"No Order Subjects found","rows-per-page-items":[5, 10, 25]},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"headers",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
          'column sortable',
          _vm.pagination.descending ? 'desc' : 'asc',
          header.value === _vm.pagination.sortBy ? 'active' : '',
        ],on:{"click":function($event){return _vm.changeSort(header.value)}}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"2000"}},[_c('div',{staticClass:"pt-3",staticStyle:{"display":"inline-block"},attrs:{"slot":"activator"},slot:"activator"},[_vm._v(" "+_vm._s(header.text)+" ")]),_c('span',[_vm._v(" "+_vm._s(header.tooltip)+" ")])]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("arrow_upward")]),_c('v-text-field',{staticClass:"pa-0 mx-0 mb-0 mt-1",attrs:{"autofocus":_vm.autofocus && header.value === 'customerName'},on:{"click":function($event){$event.stopPropagation();},"input":function($event){return _vm.$emit('reloadRequested')}},model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}})],1)}),0)]}},{key:"items",fn:function(props){return [_c('tr',{class:[
        {
          selected: _vm.selectedOrderSubject && props.item.id == _vm.selectedOrderSubject.shapeOrderSubjectId,
        },
        'order-subject',
      ],attrs:{"active":_vm.selectedOrderSubject && props.item.id == _vm.selectedOrderSubject.shapeOrderSubjectId},on:{"click":function($event){return _vm.$emit('clickedOrderSubject', props.item)}}},[_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.customerName))]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.name))]),_c('td',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.orderNumber))])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }