<template>
  <v-dialog v-model="showInternal" max-width="400">
    <v-card class="text-xs-left">
      <v-card-title class="headline">{{ headline }}</v-card-title>
      <v-card-text>
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          flat
          color="primary"
          @click="
            okPressed = true;
            $emit('dismissed');
            $emit('ok');
          "
        >
          {{ textOk === undefined ? "Ok" : textOk }}
        </v-btn>
        <v-btn flat color="secondary" @click="$emit('dismissed')">
          {{ textCancel === undefined ? "Cancel" : textCancel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["show", "headline", "text", "textOk", "textCancel"],

  watch: {
    show(value) {
      // watch for changes to the outside controlled visibility and store them inside this component
      if (value) {
        this.okPressed = false;
      }
      this.showInternal = value;
    },

    showInternal(value) {
      // watch for changes to the inside controlled visibility and inform outside world if component was closed
      if (!value) {
        this.$emit("dismissed");
        if (!this.okPressed) {
          this.$emit("cancel");
        }
      }
    },
  },

  data() {
    return {
      okPressed: false,
      showInternal: this.show,
    };
  },
};
</script>
