<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs12>
        <app-task-table></app-task-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TaskTable from "@/components/tasks/TaskTable.vue";
export default {
  components: {
    appTaskTable: TaskTable,
  },
};
</script>
