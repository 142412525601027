import store from "../store";

export function updateStoreByMessage(messageData) {
  console.log("Received notification", messageData);

  if (messageData.action === undefined) {
    throw new Error("No action in message");
  }
  let action = messageData.action;

  if (action === "delete") {
    return taskGroupDeleted(messageData);
  }

  if (action === "update") {
    return taskGroupUpdated(messageData);
  }
}

function taskGroupDeleted(messageData) {
  store.commit("taskGroup/deleteTaskGroup", messageData.deleted);
}

function taskGroupUpdated(messageData) {
  store.commit("taskGroup/taskGroupUpdated", messageData.updated);
}
