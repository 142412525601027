"use strict";

import { bootstrap } from "@/utilities/vuex";
import axios from "@/axios";
import _ from "lodash";
import { showMessage } from "@/utilities/utilities";
import { applyMappings } from "@/utilities/mappings";
import { clearMappingResult } from "../../../utilities/mappings";

const PRE_TEXT_CANCEL_HTML = "&lt;TASK WAS CANCELLED BY EDITOR&gt;";
const PRE_TEXT_CANCEL_PLAIN = "<TASK WAS CANCELLED BY EDITOR>";

function prependCancelMessage(task) {
  if (task.type === "snippet") {
    if (task.snippet) {
      if (!task.snippet.startsWith(PRE_TEXT_CANCEL_HTML)) {
        task.snippet = PRE_TEXT_CANCEL_HTML + " " + task.snippet;
      }
    } else {
      task.snippet = PRE_TEXT_CANCEL_HTML;
    }
  } else {
    if (task.showHeadline) {
      if (task.headline) {
        if (!task.headline.startsWith(PRE_TEXT_CANCEL_PLAIN)) {
          task.headline = PRE_TEXT_CANCEL_PLAIN + " " + task.headline;
        }
      } else {
        task.headline = PRE_TEXT_CANCEL_PLAIN;
      }
    }
    if (task.showAbstract) {
      if (task.abstract) {
        if (!task.abstract.startsWith(PRE_TEXT_CANCEL_HTML)) {
          task.abstract = PRE_TEXT_CANCEL_HTML + " " + task.abstract;
        }
      } else {
        task.abstract = PRE_TEXT_CANCEL_HTML;
      }
    }
  }
}

function diffTasks(task1, task2) {
  const props = ["headline", "snippet", "abstract", "author", "reviewer", "status"];
  for (let prop of props) {
    if (task1[prop] !== task2[prop]) {
      return true;
    }
  }
  return false;
}

function isTaskAssignableToUser(task) {
  return task.status === "open" || task.status === "ready for review" || task.status === "done";
}

function isTaskEditing(task, rootGetters) {
  const user = rootGetters["user/user"];
  return (
    user &&
    ((task.status === "write" && task.author === user.displayableId) ||
      (task.status === "review" && task.reviewer === user.displayableId))
  );
}

function isTaskFinishedByUser(task, rootGetters) {
  const user = rootGetters["user/user"];
  return (
    user &&
    ((task.status === "ready for review" && task.author === user.displayableId) ||
      (task.status === "done" && task.reviewer === user.displayableId))
  );
}

function isTaskDone(task) {
  return task.status === "done";
}

function isTaskRequiringAttention(task, rootGetters) {
  return (
    (isTaskAssignableToUser(task) || isTaskEditing(task, rootGetters)) &&
    !isTaskFinishedByUser(task, rootGetters) &&
    !isTaskDone(task)
  );
}

function createTaskContainer(task) {
  return {
    task,
    taskEdit: _.cloneDeep(task),
    // loading: false,
    debouncedUpdate: _.debounce(
      function (dispatch) {
        if (diffTasks(this.task, this.taskEdit)) {
          dispatch("updateTask", task.id);
        }
      },
      1000,
      { trailing: true }
    ),
    cloudState: "refresh",
    lastFailed: null, // refresh, update, shape
    changingStatus: false, // true if task.status has changed and is in progress of saving
    committingToSource: false,
  };
}

export default bootstrap({
  namespaced: true,

  state: {
    cloudState: "refresh",
    loading: false,
    showInstructions: false,
    hasDeletedTasks: false,
    wasUpdated: false,
    taskGroupId: null,
    taskGroup: null,
    taskGroupEdit: null,
    taskContainers: [],
    updatingComment: false,
    lastCommentError: null,
    relatedTaskGroups: [],
  },

  getters: {
    getTaskContainer(state) {
      return (id) => {
        return state.taskContainers.find((taskContainer) => {
          return id === taskContainer.task.id;
        });
      };
    },

    isDeletedTasksPresent(state) {
      let hasDeletedTasks = false;

      _(state.taskContainers).forEach(function (container) {
        hasDeletedTasks |= container.task.status === "deleted";
      });

      state.hasDeletedTasks = hasDeletedTasks;

      if (hasDeletedTasks) {
        _(state.taskContainers).forEach(function (container) {
          container.task.status = "deleted";
        });
      }

      return state.hasDeletedTasks;
    },
    isAnyTaskRequiringAttention(state, getters, rootState, rootGetters) {
      for (let taskContainer of state.taskContainers) {
        if (isTaskRequiringAttention(taskContainer.task, rootGetters)) {
          return true;
        }
      }
      return false;
    },

    isAnyTaskWorkedOnByUser(state, getters, rootState, rootGetters) {
      for (let taskContainer of state.taskContainers) {
        if (isTaskEditing(taskContainer.task, rootGetters)) {
          return true;
        }
      }
      return false;
    },

    isTaskEditing(state, getters, rootState, rootGetters) {
      return (id) => {
        const task = getters.getTaskContainer(id).task;
        return isTaskEditing(task, rootGetters);
      };
    },

    isTaskAssignableToUser(state, getters) {
      return (id) => {
        const task = getters.getTaskContainer(id).task;
        return isTaskAssignableToUser(task);
      };
    },

    isTaskWorkedOnByUser(state, getters) {
      return (id) => getters.isTaskEditing(id);
    },

    isTaskFinishedByUser(state, getters, rootState, rootGetters) {
      return (id) => {
        const task = getters.getTaskContainer(id).task;
        const user = rootGetters["user/user"];
        return isTaskFinishedByUser(task, user);
      };
    },

    isTaskRequiringAttention(state, getters, rootState, rootGetters) {
      return (id) => {
        const task = getters.getTaskContainer(id).task;
        return isTaskRequiringAttention(task, rootGetters);
      };
    },
  },

  mutations: {
    updateTaskContainer(state, taskContainer) {
      const result = state.taskContainers.find((tc) => tc.task.id === taskContainer.task.id);
      Object.assign(result, taskContainer);
      Object.assign(result.task, taskContainer.task);
      Object.assign(result.taskEdit, taskContainer.taskEdit);
      result.cloudState = taskContainer.cloudState;
    },

    setCloudStateInTaskContainer(state, payload) {
      const result = state.taskContainers.find((tc) => tc.task.id === payload.taskId);
      result.cloudState = payload.cloudState;
    },

    deleteTaskGroup(state, id) {
      if (state.taskGroupId === id) {
        _(state.taskContainers).forEach(function (container) {
          container.task.status = "deleted";
        });
        state.hasDeletedTasks = true;
      }
    },

    taskGroupUpdated(state, id) {
      if (state.taskGroupId === id) {
        state.wasUpdated = true;
      }
    },
  },

  actions: {
    fetchTaskGroup({ commit, dispatch }, id) {
      commit("setTaskGroupId", id);
      commit("setLoading", true);
      commit("setCloudState", "refresh");
      return axios
        .get("/task-groups/withchildren/" + id)
        .then((result) => {
          console.log("success fetching task group", result);
          commit("setTaskGroup", result.data);
          commit("setTaskGroupEdit", _.cloneDeep(result.data));

          const taskContainers = result.data.tasks.map((task) => createTaskContainer(task));
          commit("setTaskContainers", taskContainers);
        })
        .catch((error) => {
          console.log("error fetching task group", error);
          dispatch(
            "showMessage",
            {
              title: "Error while fetching task group data",
              message: "" + error,
            },
            { root: true }
          );
        })
        .then(() => {
          commit("setLoading", false);
        });
    },

    initEditOfTask({ getters, rootGetters, commit, dispatch }, taskId) {
      const taskContainer = getters["getTaskContainer"](Number(taskId));
      const user = rootGetters["user/user"];
      const taskEdit = taskContainer.taskEdit;
      if (taskEdit.status === "open") {
        taskEdit.status = "write";
        taskEdit.author = user.displayableId;
      } else if (taskEdit.status === "ready for review") {
        taskEdit.status = "review";
        taskEdit.reviewer = user.displayableId;
      } else {
        return;
      }

      commit("updateTaskContainer", taskContainer);
      dispatch("updateTask", taskId);
    },

    goToNextState({ getters, rootGetters, commit, dispatch }, taskId) {
      const taskContainer = getters.getTaskContainer(Number(taskId));
      const user = rootGetters["user/user"];
      const taskEdit = taskContainer.taskEdit;
      switch (taskEdit.status) {
        case "open":
          taskEdit.status = "write";
          taskEdit.author = user.displayableId;
          break;
        case "done":
        case "ready for review":
          taskEdit.status = "review";
          taskEdit.reviewer = user.displayableId;
          break;
        case "write":
          taskEdit.status = "ready for review";
          break;
        case "review":
          // taskEdit.status = 'done'
          // break
          return dispatch("goToDoneState", taskId);
        default:
          return;
      }

      commit("updateTaskContainer", taskContainer);
      return dispatch("updateTask", taskId);
    },

    async submitTaskToSource({ getters, commit, dispatch }, task) {
      let taskId = task.id;
      let taskContainer = getters.getTaskContainer(Number(taskId));

      taskContainer.cloudState = "progress";
      taskContainer.committingToSource = true;
      commit("updateTaskContainer", taskContainer);

      taskContainer.taskEdit.status = "done";
      const result = await dispatch("updateTask", taskId);

      const taskGroup = getters.taskGroup;
      const editorialProduct = applyMappings(taskGroup.orderSubject, taskGroup.leadArticleLanguage, [task]);
      const documentId = taskGroup.shapeLeadArticleId;
      const shapeOrderSubjectId = taskGroup.orderSubject.shapeOrderSubjectId;

      let clearedResult = clearMappingResult(editorialProduct);

      const route = `documents/${documentId}/order-subjects/${shapeOrderSubjectId}?task=${task.id}`;
      let res = await axios.put(route, clearedResult);

      console.log("success storing in shape", res.data);

      return result;
    },

    async goToDoneState({ getters, commit, dispatch }, taskId) {
      let taskContainer = null;
      let oldStatus = null;
      try {
        taskContainer = getters.getTaskContainer(Number(taskId));
        oldStatus = taskContainer.taskEdit.status;

        taskContainer.cloudState = "progress";
        taskContainer.committingToSource = true;
        commit("updateTaskContainer", taskContainer);

        // cancel debounce
        taskContainer.debouncedUpdate.cancel();

        // check if we simply set to done or if we need to write to SHAPE first
        const tasks = getters.taskContainers.map((c) => c.task);
        const otherTaskNotDone = tasks.find((task) => task.id !== taskId && task.status !== "done");

        // just set to done and exit
        if (otherTaskNotDone) {
          console.log("there are still undone tasks -> not writing to shape");
          taskContainer.taskEdit.status = "done";
          return dispatch("updateTask", taskId);
        }

        // save edits
        await dispatch("updateTask", taskId);
        if (taskContainer.lastFailed === "update") {
          taskContainer.lastFailed = "shape";
          commit("updateTaskContainer", taskContainer);
          return;
        }
        taskContainer.cloudState = "progress";
        commit("updateTaskContainer", taskContainer);

        // all other tasks are done -> commit to SHAPE
        const taskGroup = getters.taskGroup;
        const editorialProduct = applyMappings(taskGroup.orderSubject, taskGroup.leadArticleLanguage, tasks);
        const documentId = taskGroup.shapeLeadArticleId;
        const shapeOrderSubjectId = taskGroup.orderSubject.shapeOrderSubjectId;

        console.log("sending to shape", editorialProduct);
        let res = await axios.put(`documents/${documentId}/order-subjects/${shapeOrderSubjectId}`, editorialProduct);

        console.log("success storing in shape", res.data);

        // set this task to done
        taskContainer.taskEdit.status = "done";
        return dispatch("updateTask", taskId);
      } catch (error) {
        console.log("error transitioning to done state", error);
        if (taskContainer) {
          taskContainer.cloudState = "error";
          taskContainer.lastFailed = "shape";
          taskContainer.taskEdit.status = oldStatus;
        }
        showMessage(dispatch, "Shape is not available at the moment, please try again later", "" + error);
      } finally {
        if (taskContainer) {
          taskContainer.committingToSource = false;
          commit("updateTaskContainer", taskContainer);
        }
      }
    },

    cancelState({ getters, rootGetters, commit, dispatch }, taskId) {
      const taskContainer = getters.getTaskContainer(Number(taskId));
      const user = rootGetters["user/user"];
      const taskEdit = taskContainer.taskEdit;

      if (taskEdit.status === "write" && taskEdit.author === user.displayableId) {
        taskEdit.status = "open";
        taskEdit.author = null;
        prependCancelMessage(taskEdit);
      } else if (taskEdit.status === "review" && taskEdit.reviewer === user.displayableId) {
        taskEdit.status = "ready for review";
        taskEdit.reviewer = null;
        prependCancelMessage(taskEdit);
      } else if (taskEdit.status === "done") {
        taskEdit.status = "open";
        taskEdit.author = null;
        taskEdit.reviewer = null;
      }

      commit("updateTaskContainer", taskContainer);
      return dispatch("updateTask", taskId);
    },

    refreshComment({ getters, commit, dispatch }) {
      commit("setCloudState", "progress");
      return axios
        .get("/task-groups/" + getters.taskGroupId)
        .then((result) => {
          console.log("success refreshing comment", result);
          commit("setTaskGroup", { ...getters.taskGroup, ...result.data });
          commit("setTaskGroupEdit", _.cloneDeep(getters.taskGroup));
          commit("setCloudState", "check");
        })
        .catch((error) => {
          commit("setCloudState", "error");
          showMessage(dispatch, "Error while refreshing comment", "" + error);
          // dispatch(
          //     'showMessage',
          //     {
          //         title: 'Error while refreshing comment',
          //         message: '' + error
          //     },
          //     { root: true }
          // )
        });
    },

    updateComment({ commit, dispatch, getters }) {
      if (getters.updatingComment) {
        // have to wait for previous update call which is not finished
        // console.log( 'Deferring update' )
        dispatch("commentInputChanged");
        return;
      }
      commit("setUpdatingComment", true);
      commit("setCloudState", "progress");
      // console.log( 'Calling update', getters.taskGroupEdit.comment )
      return axios
        .put("/task-groups/" + getters.taskGroupId, {
          comment: getters.taskGroupEdit.comment,
          version: getters.taskGroupEdit.version,
        })
        .then((result) => {
          console.log("success storing comment", result);
          commit("setTaskGroup", { ...getters.taskGroup, ...result.data });
          getters.taskGroupEdit.version = result.data.version;
          commit("setTaskGroupEdit", getters.taskGroupEdit);
          commit("setCloudState", "check");
        })
        .catch((error) => {
          commit("setLastCommentError", error);
          commit("setCloudState", "error");
          if (error.response && error.response.status === 409) {
            showMessage(
              dispatch,
              "Error while storing comment",
              "The comment has been edited on the server. Please refresh and incorporate your changes."
            );
            // dispatch(
            //     'showMessage',
            //     {
            //         title: 'Error while storing comment',
            //         message:
            //             'The comment has been edited on the server. Please refresh and incorporate your changes.'
            //     },
            //     { root: true }
            // )
          } else {
            showMessage(dispatch, "Error while storing comment", "" + error);
            // dispatch(
            //     'showMessage',
            //     {
            //         title: 'Error while storing comment',
            //         message: '' + error
            //     },
            //     { root: true }
            // )
          }
        })
        .then(() => {
          commit("setUpdatingComment", false);
        });
    },

    commentInputChanged: _.debounce(
      ({ state, dispatch }) => {
        if (state.taskGroupEdit.comment !== state.taskGroup.comment) {
          console.log("scheduling storing comment changes", '"' + state.taskGroupEdit.comment + '"');
          dispatch("updateComment");
        }
      },
      1000,
      { trailing: true }
    ),

    updateTask({ getters, commit, dispatch }, taskId) {
      const taskContainer = getters.getTaskContainer(taskId);
      const taskEdit = taskContainer.taskEdit;

      taskContainer.changingStatus = taskContainer.task.status !== taskEdit.status;
      taskContainer.cloudState = "progress";
      taskContainer.lastFailed = null;
      commit("updateTaskContainer", taskContainer);
      return axios
        .put("/tasks/" + taskId, {
          headline: taskEdit.headline,
          abstract: taskEdit.abstract,
          snippet: taskEdit.snippet,
          author: taskEdit.author,
          reviewer: taskEdit.reviewer,
          status: taskEdit.status,
        })
        .then((result) => {
          console.log("success storing task", result);
          taskContainer.task = result.data;
          taskContainer.cloudState = "check";
          taskContainer.changingStatus = false;
          commit("updateTaskContainer", taskContainer);
        })
        .catch((error) => {
          taskContainer.lastFailed = "update";
          taskContainer.cloudState = "error";
          taskContainer.changingStatus = false;
          if (error.response && error.response.status === 404) {
            showMessage(
              dispatch,
              "Error while storing task",
              "Task could not be found. Maybe it has been deleted by another user."
            );
            // dispatch(
            //     'showMessage',
            //     {
            //         title: 'Error while storing task',
            //         message: 'Task could not be found. Maybe it has been deleted by another user.'
            //     },
            //     { root: true }
            // )
          } else {
            showMessage(
              dispatch,
              "Error while storing task",
              (error.response && error.response.data.message) || "" + error
            );
            // dispatch(
            //     'showMessage',
            //     {
            //         title: 'Error while storing task',
            //         message: ( error.response && error.response.data.message ) || '' + error
            //     },
            //     { root: true }
            // )
          }

          // check if user wanted to change into edit mode
          if (
            taskEdit.status !== taskContainer.task.status &&
            (taskEdit.status === "write" || taskEdit.status === "review")
          ) {
            return dispatch("refreshTask", taskId);
          }
        })
        .then(() => {
          commit("updateTaskContainer", taskContainer);
        });
    },

    refreshTask({ getters, commit, dispatch }, taskId) {
      const taskContainer = getters.getTaskContainer(taskId);
      taskContainer.cloudState = "progress";
      taskContainer.lastFailed = null;
      commit("updateTaskContainer", taskContainer);
      return axios
        .get("/tasks/" + taskId)
        .then((result) => {
          console.log("success refreshing task", result);
          taskContainer.task = result.data;
          taskContainer.taskEdit = _.cloneDeep(result.data);
          taskContainer.cloudState = "check";
        })
        .catch((error) => {
          taskContainer.cloudState = "error";
          taskContainer.lastFailed = "refresh";
          showMessage(dispatch, "Error while refreshing task", "" + error);
          // dispatch(
          //     'showMessage',
          //     {
          //         title: 'Error while refreshing task',
          //         message: '' + error
          //     },
          //     { root: true }
          // )
        })
        .then(() => {
          commit("updateTaskContainer", taskContainer);
        });
    },

    taskInputChanged({ getters, dispatch }, taskId) {
      const taskContainer = getters.getTaskContainer(taskId);
      taskContainer.debouncedUpdate(dispatch);
    },

    getRelatedTaskGroups({ commit, dispatch }, id) {
      return axios
        .get("/task-groups/" + id + "/related-groups")
        .then((result) => {
          commit("setRelatedTaskGroups", result.data);
          return result.data;
        })
        .catch((error) => {
          dispatch(
            "showMessage",
            {
              title: "Error while fetching related task group data",
              message: "" + error,
            },
            { root: true }
          );

          return [];
        });
    },
  },
});
