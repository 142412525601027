<template>
  <v-card :color="requiresAttention ? 'white' : 'grey lighten-2'">
    <v-card-title>
      <v-avatar :color="isSnippet ? 'amber' : 'teal'">
        <span class="white--text headline">{{ isSnippet ? "Sn" : task.language }}</span>
      </v-avatar>
      <template v-if="isAssignToMe">
        <v-spacer></v-spacer>
        <v-btn
          flat
          :disabled="disableAssignToMe"
          class="subheading font-weight-bold"
          color="primary"
          @click="onAssignToMe"
        >
          Assign to me
        </v-btn>
      </template>
      <v-spacer></v-spacer>
      <app-cloud
        :state="taskContainer.cloudState"
        class="ml-3 mt-0"
        @refresh="onRefreshTask"
        @error="onErrorTask"
        @checkTimeout="onCheckTimeoutTask"
      >
      </app-cloud>
      <v-subheader>{{ task.status }}</v-subheader>
    </v-card-title>

    <v-card-text>
      <v-layout v-if="!isSnippet && task.showHeadline" row class="mx-0 my-2" align-center>
        <template v-if="isEditing">
          <v-textarea
            :label="headlineLabel"
            ref="headlineEditor"
            :disabled="disableEditor"
            autoGrow
            rows="1"
            v-model="taskEdit.headline"
            @input="onHeadlineInput"
          >
            <template slot="append">
              <v-tooltip bottom nudge-right open-delay="2000">
                <v-icon
                  slot="activator"
                  :disabled="disableEditor"
                  @click="pasteHeadline"
                  style="transform: rotateY(180deg)"
                  >mdi-clipboard-play-outline</v-icon
                >
                <span>Copy lead articles headline in here</span>
              </v-tooltip>
            </template>
          </v-textarea>
        </template>
        <div v-else class="task-text mb-3 text-xs-left">
          <div class="caption text--secondary">{{ headlineLabel }}</div>
          <span>{{ task.headline }}</span>
        </div>
      </v-layout>

      <v-layout v-if="isSnippet || task.showAbstract" column class="mx-0 my-2 text-xs-left">
        <app-text-editor
          v-if="isEditing"
          class="mb-3"
          ref="mainEditor"
          :disabled="disableEditor"
          :label="mainEditorLabel"
          counter
          v-model="mainEditorModel"
          @input="onMainEditorInput"
          @mounted="onMountedMainEditor"
        >
        </app-text-editor>

        <div v-show="!isEditing" class="caption text--secondary">
          {{ mainEditorLabel + " (" + mainCharacterCount + ")" }}
        </div>
        <div
          v-show="!isEditing"
          class="task-text mb-3"
          ref="mainTextDisplay"
          v-html="isSnippet ? task.snippet : task.abstract"
        ></div>
      </v-layout>

      <v-layout justify-space-between row wrap class="mx-0 my-2">
        <div class="text-xs-left">
          <div class="caption text--secondary">Author</div>
          {{ task.author }}
        </div>
        <div class="text-xs-left">
          <div class="caption text--secondary">Reviewer</div>
          {{ task.reviewer }}
        </div>
      </v-layout>
    </v-card-text>

    <v-card-actions>
      <v-btn v-if="submitToReviewButtonLabel" flat :disabled="disableSubmit" color="primary" @click="onSubmit">{{
        submitToReviewButtonLabel
      }}</v-btn>
      <v-btn
        v-if="submitToSourceButtonLabel && !isAssignToMe"
        flat
        :disabled="disableSubmit"
        color="primary"
        @click="onSubmitToSource"
        >{{ submitToSourceButtonLabel }}</v-btn
      >
      <v-btn v-if="cancelButtonLabel" flat :disabled="disableCancel" color="primary" @click="onCancel">{{
        cancelButtonLabel
      }}</v-btn>

      <v-btn v-if="submitToSourceButtonLabel" flat color="error" @click="sendDirectlyToSource"
        >Send directly to {{ isInboxArticle ? "Inbox" : "Shape" }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import TextEditorQuill from "./TextEditorQuill.vue";
import Cloud from "@/components/shared/Cloud.vue";
import * as util from "@/utilities/utilities";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  props: ["taskContainer", "taskGroup"],

  components: {
    appCloud: Cloud,
    appTextEditor: TextEditorQuill,
  },

  data() {
    return {
      mainCharacterCount: 0,

      // jan: we need the text editor component to get the character count without html for
      //  disableSubmit() to work. When this method is first called, the component is not there
      //  yet and since this.$refs is not reactive, it will not be called again. Therefore the
      //  editor component sends an event when it is mounted, which is stored in this reactive
      //  property, which is used in disableSubmit(), which is therefore called. :)
      mountedMainEditor: false,
    };
  },

  watch: {
    isEditing(value) {
      if (!value) {
        this.$nextTick(() => {
          this.updateMainCharacterCount();
        });
      }
    },
  },

  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("taskGroup", [
      "isTaskEditing",
      "isTaskAssignableToUser",
      "isTaskFinishedByUser",
      "isTaskRequiringAttention",
    ]),

    mainEditorModel: {
      get() {
        return this.isSnippet ? this.taskEdit.snippet : this.taskEdit.abstract;
      },

      set(value) {
        this.taskEdit[this.isSnippet ? "snippet" : "abstract"] = value;
      },
    },

    isInboxArticle() {
      return this.taskGroup.source === "INBOX";
    },

    isSnippet() {
      return this.task.type === "snippet";
    },

    headlineLabel() {
      const headlineLabel = this.taskEdit.headlineLabel;
      if (headlineLabel === undefined || headlineLabel === null) {
        return `Headline (${this.taskEdit.headline ? this.taskEdit.headline.length : 0})`;
      } else {
        return `${headlineLabel} (${this.taskEdit.headline ? this.taskEdit.headline.length : 0})`;
      }
    },

    mainEditorLabel() {
      if (this.isSnippet) {
        const snippetLabel = this.task.snippetLabel;
        return snippetLabel === undefined || snippetLabel === null ? "Snippet" : snippetLabel;
      } else {
        const abstractLabel = this.taskEdit.abstractLabel;
        return abstractLabel === undefined || abstractLabel === null ? "Abstract" : abstractLabel;
      }
    },

    task() {
      return this.taskContainer.task;
    },

    taskEdit() {
      return this.taskContainer.taskEdit;
    },

    disableEditor() {
      return this.taskContainer.changingStatus || this.taskContainer.committingToSource;
    },

    disableProgress() {
      return this.taskContainer.cloudState === "progress" || this.taskContainer.cloudState === "error";
    },

    disableAssignToMe() {
      return this.disableProgress;
    },

    disableSubmit() {
      if (this.disableProgress) {
        return true;
      }
      if (this.taskEdit.status !== "write" && this.taskEdit.status !== "review") {
        return false;
      }

      if (this.taskEdit.type === "snippet") {
        // console.log( 'Disable?', this.mainCharacterCount, this.$refs.snippetEditor && this.$refs.snippetEditor.characterCount < 4 )
        return this.mountedMainEditor && this.$refs.mainEditor && this.$refs.mainEditor.characterCount < 4;
      } else if (
        this.taskEdit.showHeadline &&
        (this.taskEdit.headline === undefined || this.taskEdit.headline === null || this.taskEdit.headline.length < 4)
      ) {
        return true;
      } else if (
        this.taskEdit.showAbstract &&
        this.mountedMainEditor &&
        this.$refs.abstractEditor &&
        this.$refs.abstractEditor.characterCount < 4
      ) {
        return true;
      }

      return false;
    },

    disableCancel() {
      return this.disableProgress;
    },

    isEditing() {
      return this.isTaskEditing(this.task.id);
    },

    isAssignableToUser() {
      return this.isTaskAssignableToUser(this.task.id);
    },

    isWorkedOnByUser() {
      return this.isEditing;
    },

    isFinishedByUser() {
      return this.isTaskFinishedByUser(this.task.id);
    },

    requiresAttention() {
      return this.isTaskRequiringAttention(this.task.id);
    },

    isAssignToMe() {
      return (
        this.task.status === "open" ||
        (this.task.status === "ready for review" && this.task.author !== this.user.displayableId)
      );
    },

    submitToReviewButtonLabel() {
      switch (this.task.status) {
        case "write":
          return this.task.author === this.user.displayableId ? "To Review" : null; // other user is editing
        case "ready for review":
          return this.task.author === this.user.displayableId ? "Review" : null; // Assign To Me
        case "done":
          return "Review";
        default:
          return null;
      }
    },

    submitToSourceButtonLabel() {
      const label = this.isInboxArticle ? "Inbox" : "Shape";
      switch (this.task.status) {
        case "write":
          return this.task.author === this.user.displayableId ? `To ${label}` : null;
        case "review":
          return this.task.reviewer === this.user.displayableId ? `To ${label}` : null;
        default:
          return null;
      }
    },

    cancelButtonLabel() {
      if (this.isEditing) {
        return "Cancel";
      } else if (this.task.status === "done") {
        return "Reopen";
      }
      return null;
    },
  },

  methods: {
    ...mapMutations("taskGroup", ["setCloudState", "setCloudStateInTaskContainer"]),
    ...mapActions("taskGroup", [
      "cancelState",
      "goToNextState",
      "taskInputChanged",
      "refreshTask",
      "updateTask",
      "goToDoneState",
      "submitTaskToSource",
    ]),

    onMainEditorInput() {
      this.taskInputChanged(this.task.id);
    },

    defaultFocus() {
      if (this.$refs.headlineEditor) {
        this.$refs.headlineEditor.focus();
        return true;
      } else if (this.$refs.mainEditor) {
        this.$refs.mainEditor.focus();
        return true;
      }
      return false;
    },

    onMountedMainEditor() {
      this.mountedMainEditor = true;
    },

    onAssignToMe() {
      this.onSubmit();
    },

    onSubmit() {
      this.goToNextState(this.task.id).then(() => {
        if (this.task.status === "write" || this.task.status === "review") {
          this.defaultFocus();
        }
        if (!this.isEditing && this.taskContainer.lastFailed !== "update") {
          this.$emit("finished", this);
        }
      });
    },

    async onSubmitToSource() {
      await this.goToDoneState(this.task.id);
      if (!this.isEditing && this.taskContainer.lastFailed !== "update") {
        this.$emit("finished", this);
      }
    },

    async sendDirectlyToSource() {
      await this.submitTaskToSource(this.task);
    },

    onCancel() {
      const isDone = this.taskEdit.status === "done";
      this.cancelState(this.task.id).then(() => {
        if (this.task.status === "write" || this.task.status === "review") {
          this.defaultFocus();
        }
        // if ( !isDone && !this.requiresAttention ) {
        // console.log( 'isEditing', this.isEditing, this.task.status, this.task.author )
        if (!this.isEditing && !isDone) {
          this.$emit("cancelled");
        }
      });
    },

    updateMainCharacterCount() {
      // console.log( 'updateMainCharacterCount', this.$refs.mainTextDisplay )
      if (this.$refs.mainTextDisplay) {
        const text = this.$refs.mainTextDisplay.innerText;
        // console.log( 'Inner Text', text )
        const count = util.getHtmlCharacterCount(text);
        // console.log( 'Count', count )
        this.mainCharacterCount = count;
      }
    },

    onRefreshTask() {
      this.refreshTask(this.task.id);
    },

    onErrorTask() {
      if (this.taskContainer.lastFailed === "refresh") {
        this.refreshTask(this.task.id);
      } else if (this.taskContainer.lastFailed === "update") {
        this.updateTask(this.task.id);
      } else if (this.taskContainer.lastFailed === "shape") {
        this.goToDoneState(this.task.id);
      }
    },

    onCheckTimeoutTask() {
      this.setCloudStateInTaskContainer({
        taskId: this.task.id,
        cloudState: "refresh",
      });
    },

    pasteHeadline() {
      this.taskEdit.headline = this.taskGroup.leadArticleHeadline;
      this.taskInputChanged(this.task.id);
    },

    onHeadlineInput() {
      this.taskInputChanged(this.task.id);
    },

    onAbstractInput() {
      this.taskInputChanged(this.task.id);
    },
  },

  mounted() {
    this.updateMainCharacterCount();
  },
};
</script>

<style>
.task-text p {
  margin-bottom: 0;
}
</style>

<style lang="scss" scoped>
.task-text {
  word-break: break-word;
  word-wrap: break-word;
}
</style>
