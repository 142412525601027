<template>
  <div>
    <v-data-table
      id="table-task-templates"
      :headers="headers"
      :items="taskTemplateValidators"
      :hide-actions="true"
      no-data-text="No task templates have been added yet"
      class="elevation-1"
    >
      <template slot="headerCell" slot-scope="props">
        <v-tooltip bottom>
          <v-btn
            v-if="props.header.text === 'Action'"
            icon
            color="primary"
            small
            @click="onAddTemplate"
            :disabled="disabled"
            slot="activator"
          >
            <v-icon>add</v-icon>
          </v-btn>
          <div v-else slot="activator">
            {{ props.header.text }}
          </div>
          <span>
            {{ props.header.tooltip }}
          </span>
        </v-tooltip>
      </template>

      <template slot="items" slot-scope="props">
        <tr>
          <td class="text-xs-left">
            <v-text-field
              solo
              flat
              :disabled="disabled"
              @change="props.item.articleLanguage.$touch()"
              v-model="props.item.$model.articleLanguage"
              :error-messages="errorsArticleLanguage(props.item.articleLanguage)"
            >
            </v-text-field>
          </td>
          <td class="text-xs-left">
            <v-text-field
              solo
              flat
              :disabled="disabled"
              validate-on-blur
              @change="props.item.language.$touch()"
              v-model="props.item.$model.language"
              :error-messages="errorsLanguage(props.item.language)"
            >
            </v-text-field>
          </td>
          <td>
            <v-checkbox :disabled="disabled" v-model="props.item.$model.showHeadline"> </v-checkbox>
          </td>
          <td class="text-xs-left">
            <v-text-field solo flat :disabled="disabled" v-model="props.item.$model.headlineLabel"> </v-text-field>
          </td>
          <td>
            <v-checkbox :disabled="disabled" v-model="props.item.$model.showAbstract"> </v-checkbox>
          </td>
          <td class="text-xs-left">
            <v-text-field solo flat :disabled="disabled" v-model="props.item.$model.abstractLabel"> </v-text-field>
          </td>
          <td class="px-0 text-xs-center">
            <v-layout row fill-height class="justify-center" no-wrap>
              <v-btn icon :disabled="disabled" @click.prevent.stop="onDeleteTemplate(props.item.$model)">
                <v-icon color="secondary"> mdi-trash-can-outline </v-icon>
              </v-btn>
            </v-layout>
          </td>
        </tr>
      </template>
    </v-data-table>
    <app-confirm-dialog
      :show="showConfirmDelete"
      headline="Delete Template?"
      :text="textConfirmDelete"
      textOk="Yes"
      textCancel="No"
      @dismissed="showConfirmDelete = false"
      @ok="onOkDelete"
      @cancel="onCancelDelete"
    >
    </app-confirm-dialog>
  </div>
</template>

<script>
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import languageCodes from "@/utilities/lang_codes_iso639-1";

export default {
  props: ["taskTemplates", "disabled"],

  components: {
    appConfirmDialog: ConfirmDialog,
  },

  mixins: [validationMixin],

  validations: {
    taskTemplates: {
      $each: {
        articleLanguage: {
          required,
          iso: (v) => v === "*" || languageCodes.includes(v),
        },

        language: {
          required,
          iso: (v) => languageCodes.includes(v),
        },
      },
    },
  },

  data() {
    return {
      showConfirmDelete: false,
      templateToDelete: null,

      headers: [
        {
          text: "Article Language",
          tooltip: "Language the article was written in",
          align: "left",
          sortable: false,
          class: "string",
          value: "articleLanguage",
        },
        {
          text: "Language",
          tooltip: "Language to translate the article to",
          align: "left",
          sortable: false,
          class: "string",
          value: "language",
        },
        {
          text: "Headline pre-selected",
          tooltip: "Request a headline by default",
          align: "left",
          sortable: false,
          class: "boolean",
          value: "showHeadline",
        },
        {
          text: "Headline Label",
          tooltip: "Label for the editors headline field",
          align: "left",
          sortable: false,
          class: "string",
          value: "headlineLabel",
        },
        {
          text: "Abstract pre-selected",
          tooltip: "Request an abstract by default",
          align: "left",
          sortable: false,
          class: "boolean",
          value: "showAbstract",
        },
        {
          text: "Abstract Label",
          tooltip: "Label for the editors abstract field",
          align: "left",
          sortable: false,
          class: "string",
          value: "abstractLabel",
        },
        {
          text: "Action",
          tooltip: "Add a new template",
          align: "left",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    taskTemplateValidators() {
      return Object.values(this.$v.taskTemplates.$each.$iter);
    },

    textConfirmDelete() {
      return this.templateToDelete
        ? `Do you really want to delete the template for article language '${this.templateToDelete.articleLanguage}' and language '${this.templateToDelete.language}'?`
        : "";
    },
  },

  methods: {
    onAddTemplate() {
      this.taskTemplates.push({
        articleLanguage: null,
        language: null,
        showHeadline: true,
        headlineLabel: "Headline",
        showAbstract: true,
        abstractLabel: "Abstract",
      });
    },

    onDeleteTemplate(template) {
      this.templateToDelete = template;
      console.log("onDeleteTemplate", template);
      this.showConfirmDelete = true;
    },

    onOkDelete() {
      this.taskTemplates.splice(this.taskTemplates.indexOf(this.templateToDelete), 1);
      console.log("Ok delete");
    },

    onCancelDelete() {
      this.templateToDelete = null;
      console.log("Cancel delete");
    },

    errorsArticleLanguage(v) {
      if (!v.$dirty) return null;
      if (!v.required) return ["Value required"];
      if (!v.iso) {
        return ["No valid language code (e.g. de or wildcard *)"];
      }
      return null;
    },

    errorsLanguage(v) {
      if (!v.$dirty) return null;
      if (!v.required) return ["Value required"];
      if (!v.iso) {
        return ["No valid language code"];
      }
      return null;
    },

    validate() {
      this.$v.$touch();
      return !this.$v.taskTemplates.$invalid;
    },

    resetValidation() {
      this.$v.$reset();
    },
  },
};
</script>

<style lang="scss">
#table-task-templates .v-input {
  position: relative;
  height: 100%;

  &.v-input--checkbox > div.v-input__control > div.v-input__slot {
    width: calc(100% + 20px);
  }

  &.error--text div.v-input__slot {
    border: 1px solid red;
    border-bottom: 0;
    border-radius: 2px 2px 0 0;
  }

  div.v-input__control {
    height: 100%;
    width: 100%; // for checkbox
    margin: 0px 0px 0px -10px;

    div.v-input__slot {
      font-size: 0.8em;
      color: var(--v-secondary-text-color) !important;
      padding: 0px 10px 0px 10px;
      margin: 0;
      width: calc(100% + 10px);
      // background-color: cyan;

      min-height: 48px; // for checkbox
      justify-content: center; // for checkbox

      div.v-input--selection-controls__input {
        margin: 0; // for checkbox
      }
    }

    div.v-messages {
      // background-color: green;
      padding: 3px 10px;
      background-color: white;

      border: 1px solid red;
      border-top: 0;
      border-radius: 0 0 2px 2px;

      &:not(.error--text) {
        display: none;
      }
    }

    div.v-text-field__details {
      // display: none;
      margin: 0;
      padding: 0;
      max-width: calc(100% + 10px);
      width: calc(100% + 10px);
      // background-color: yellow;
    }
  }
}
</style>
