<template>
  <v-data-table
    v-on:update:pagination="paginationChanged"
    :headers="headers"
    :items="tasks"
    :loading="loadingTasks"
    :pagination.sync="pagination"
    :totalItems="totalTasks"
    no-data-text="No tasks for the current settings"
    :rows-per-page-items="[25, 50, 100]"
    class="elevation-1"
  >
    <template slot="headerCell" slot-scope="props">
      <v-tooltip bottom>
        <span slot="activator">
          {{ props.header.text }}
        </span>
        <span>
          {{ props.header.tooltip }}
        </span>
      </v-tooltip>
      <v-select
        v-if="filters[props.header.value]"
        :items="filters[props.header.value].values"
        v-model="filters[props.header.value].selected"
        multiple
        class="table-header-select"
        height="0"
        @input="onFilterChanged"
        solo
        flat
      >
        <v-progress-linear
          v-if="filterIsEmpty(props.header.value) && loadingFilters"
          slot="append-item"
          class="mt-2 mb-0"
          height="1"
          :active="loadingFilters"
          :indeterminate="true"
        >
        </v-progress-linear>

        <template v-if="!filterIsEmpty(props.header.value)" slot="prepend-item">
          <v-list-tile ripple @click="toggleFilterAll(props.header.value)">
            <v-list-tile-action>
              <v-icon :color="filterHasSomeSelected(props.header.value) ? 'primary' : ''">{{
                iconFilterAll(props.header.value)
              }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Select All</v-list-tile-title>
          </v-list-tile>
          <v-progress-linear
            v-if="loadingFilters"
            class="mt-2 mb-0"
            height="1"
            :active="loadingFilters"
            :indeterminate="true"
          >
          </v-progress-linear>
          <v-divider v-else class="mt-2"></v-divider>
        </template>

        <template
          slot="append"
          v-if="filters[props.header.value].values.length !== filters[props.header.value].selected.length"
        >
          <v-icon color="primary" small>mdi-filter</v-icon>
        </template>
        <template slot="selection" slot-scope="{}"> </template>
      </v-select>
    </template>
    <template slot="items" slot-scope="props">
      <tr class="tasks" @click="viewItem(props.item)">
        <td class="text-xs-left">{{ props.item.orderSubjectCustomer }}</td>
        <td class="text-xs-left">{{ props.item.orderSubjectName }}</td>
        <td class="text-xs-left">{{ props.item.taskGroupSubjectArea }}</td>
        <td class="text-xs-left">{{ props.item.leadArticleHeadline }}</td>
        <td class="text-xs-left">{{ props.item.leadArticleMediaName }}</td>
        <td class="text-xs-left">{{ props.item.type }}</td>
        <td class="text-xs-left">{{ props.item.language }}</td>
        <td class="text-xs-left">{{ props.item.status }}</td>
        <td class="text-xs-left">{{ props.item.author }}</td>
        <td class="text-xs-left">{{ props.item.reviewer }}</td>

        <!-- <td class='text-xs-left'>{{ props.item.taskGroupSubjectArea }}</td>
            <td class='text-xs-left'>{{ props.item.leadArticleLanguage }}</td>
            <td class='text-xs-left'>{{ props.item.comment }}</td> -->
        <td class="justify-center px-0">
          <!-- <v-icon small
                        class='mr-2'
                        @click='viewItem(props.item)'>
                    edit
                </v-icon> -->
          <v-btn v-if="isEditable(props.item)" icon @click.prevent.stop="editItem(props.item)">
            <v-icon small> edit </v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      headers: [
        {
          text: "Client",
          tooltip: "Kundenkurzname",
          align: "left",
          sortable: false,
          class: "string",
          value: "orderSubjectCustomer",
        },
        {
          text: "Order Subject",
          tooltip: "Auftragsthema",
          align: "left",
          sortable: false,
          class: "string",
          value: "orderSubjectName",
        },
        {
          text: "Subject Area",
          tooltip: "Subject Area",
          align: "left",
          sortable: false,
          class: "string",
          value: "subjectArea",
        },
        {
          text: "Headline",
          tooltip: "Original headline",
          align: "left",
          sortable: false,
          class: "string",
          value: "leadArticleHeadline",
        },
        {
          text: "Medium",
          tooltip: "Medium",
          align: "left",
          sortable: false,
          class: "string",
          value: "leadArticleMediaName",
        },
        {
          text: "Type",
          tooltip: "Auftragstyp",
          align: "left",
          sortable: false,
          class: "string",
          value: "type",
        },
        {
          text: "Target",
          tooltip: "Target language",
          align: "left",
          sortable: false,
          class: "string",
          value: "language",
        },
        {
          text: "Status",
          tooltip: "Status",
          align: "left",
          sortable: false,
          class: "string",
          value: "status",
        },
        {
          text: "Author",
          tooltip: "Author",
          align: "left",
          sortable: false,
          class: "string",
          value: "author",
        },
        {
          text: "Reviewer",
          tooltip: "Reviewer",
          align: "left",
          sortable: false,
          class: "string",
          value: "reviewer",
        },
        {
          text: "Actions",
          tooltip: "View details of a task or edit it",
          align: "left",
          sortable: false,
        },
      ],
    };
  },

  watch: {
    pagination: {
      handler() {
        // console.log( 'Pagination changed', value )

        this.fetchTasks();
      },
      deep: true,
    },

    loadingTasks(value) {
      if (value) {
        // console.log( 'Filters', this.filters )

        // display set filters in browser url for bookmarking
        const query = Object.values(this.filters).reduce((query, filter) => {
          // only set filters to if not all options are selected
          if (filter.initialPageLoadWithUrlFiltersTaskLoad || filter.values.length !== filter.selected.length) {
            if (filter.selected.length === 0) {
              if (!query.ef) {
                query.ef = [];
              }
              query.ef.push(filter.browserKey);
            } else {
              query[filter.browserKey] = filter.selected;
            }
          }
          return query;
        }, {});

        // console.log( 'Query', query )

        const route = {
          path: this.$router.currentRoute.path,
          query,
        };
        this.$router.replace(route);
      }
    },
  },

  computed: {
    ...mapGetters("tasks", ["tasks", "loadingTasks", "loadingFilters", "pagination", "totalTasks", "filters"]),

    ...mapGetters("user", ["user"]),

    pagination: {
      get() {
        let storePagination = this.$store.getters["tasks/pagination"];
        let tasksPerPage = localStorage.getItem("tasksPerPage");
        // Yeah. It would return NULL. Not undefined.
        if (tasksPerPage !== null) {
          storePagination.rowsPerPage = parseInt(tasksPerPage);
        }

        return storePagination;
      },

      set(value) {
        this.$store.commit("tasks/setPagination", value);
      },
    },
  },

  methods: {
    ...mapActions("tasks", ["fetchTasks", "fetchFilters", "setFilters"]),

    async onFilterChanged() {
      // await this.fetchTasks()
      // this.fetchFilters()
      await this.fetchFilters(); // filters should be fetched before tasks to see which are available for task query
      this.fetchTasks();
    },

    getOwner(task) {
      switch (task.status) {
        case "write":
          return task.author;
        case "review":
          return task.reviewer;
        default:
          return null;
      }
    },

    /**
     * Is the current allowed to edit the specified task?
     */
    isEditable(task) {
      const owner = this.getOwner(task);
      return owner === null || owner === this.user.displayableId;
    },

    paginationChanged(pagination) {
      let perPage = pagination.rowsPerPage;

      if (!isNaN(perPage) && perPage !== undefined) {
        localStorage.setItem("tasksPerPage", perPage);
      }
    },

    toggleFilterAll(filterName) {
      this.$nextTick(() => {
        const filter = this.filters[filterName];
        if (filter.values.length === filter.selected.length) {
          filter.selected = [];
        } else {
          filter.selected = filter.values.slice();
        }
        this.$store.commit("tasks/setFilters", this.filters);
        this.onFilterChanged();
      });
    },

    filterHasSomeSelected(filterName) {
      const filter = this.filters[filterName];
      return filter.selected.length > 0;
    },

    filterIsEmpty(filterName) {
      const filter = this.filters[filterName];
      return filter.values.length === 0;
    },

    iconFilterAll(filterName) {
      const filter = this.filters[filterName];
      if (filter.values.length === filter.selected.length) {
        return "mdi-close-box";
      } else if (filter.selected.length > 0) {
        return "mdi-minus-box";
      }
      return "mdi-checkbox-blank-outline";
    },

    viewItem(task) {
      console.log("Clicked view", task);
      this.$router.push("task-groups/" + task.taskGroupId);
    },

    editItem(task) {
      console.log("Clicked edit", task);
      this.$router.push({
        path: "task-groups/" + task.taskGroupId,
        query: {
          edit: task.id,
        },
      });
    },
  },

  created() {
    // console.log( 'CREATED', this.$router.currentRoute.query )

    // check if page has been called with task filters set via url query parameters
    const query = this.$router.currentRoute.query;
    let emptyFilters = [];
    if (query.ef) {
      emptyFilters = emptyFilters.concat(query.ef);
    }
    Object.values(this.filters).forEach((filter) => {
      if (emptyFilters.includes(filter.browserKey)) {
        filter.selected = [];
        filter.initialPageLoadWithUrlFiltersTaskLoad = true;
        filter.initialPageLoadWithUrlFiltersFilterLoad = true;
      } else if (query[filter.browserKey]) {
        filter.selected = [].concat(query[filter.browserKey]);
        filter.initialPageLoadWithUrlFiltersTaskLoad = true;
        filter.initialPageLoadWithUrlFiltersFilterLoad = true;
      } else if (filter.browserKey === "on") {
        // not marked as empty and no selection -> select all
        // only for orderSubjectName filter -> see SHP-8856
        filter.autoselect = true;
        filter.initialAutoselectEnableOnce = true;
      }
    });

    this.fetchFilters(); // tasks are fetched by watch pagination
  },
};
</script>

<style lang="scss">
.v-text-field.v-text-field--enclosed .v-text-field__details,
.v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  padding: 0;
}

tr.tasks {
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.table-header-select {
  width: 0;
  height: 52px;
  display: inline-block;
}
</style>
