"use strict";

import axios from "axios";
import auth from "./msopenid";
// import router from '@/router'
// import store from '@/store'

const instance = axios.create({
  // custom serializer for encoding. axios handles arrays for keys like this (tag[]=bla&tag[]=blub). Therefore it
  // is not possible to use the params mechanism with multiple same keys
  paramsSerializer: (params) => {
    let result = "";
    Object.keys(params).forEach((key) => {
      const values = [].concat(params[key]);
      values.forEach((val) => {
        if (result.length > 0) {
          result += "&";
        }
        result += key + "=" + encodeURIComponent(val);
      });
    });
    // console.log( 'PARAMS', result )
    return result;
  },
});

// eslint-disable-next-line
instance.defaults.baseURL = APP_BACKEND_URL
instance.defaults.withCredentials = true;

instance.interceptors.request.use(
  async (config) => {
    const token = await auth.getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
