"use strict";

import _ from "lodash";

const PROPS_TO_CHECK_FOR_TASK_CHANGES = ["abstract", "snippet", "headline", "reviewer", "author", "status"];

export function getHtmlCharacterCount(text) {
  // console.log( text.charCodeAt( 0 ), text.length )
  text = text.replace(/[\uFEFF\r\n]/g, "");
  // console.log( text.charCodeAt( 0 ), text.length )
  return text.length;
}

export function isTaskDifferent(task1, task2) {
  for (let prop of PROPS_TO_CHECK_FOR_TASK_CHANGES) {
    if (task1[prop] !== task2[prop]) {
      return true;
    }
  }
  return false;
}

export function diffObjects(obj1, obj2) {
  // https://stackoverflow.com/a/39813128/608194
  const allkeys = _.union(_.keys(obj1), _.keys(obj2));
  return _.reduce(
    allkeys,
    function (result, key) {
      if (!_.isEqual(obj1[key], obj2[key])) {
        result[key] = { obj1: obj1[key], obj2: obj2[key] };
      }
      return result;
    },
    {}
  );
}

export function showMessage(dispatch, title, message, color) {
  dispatch(
    "showMessage",
    {
      title,
      message,
      color,
    },
    { root: true }
  );
}
