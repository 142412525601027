"use strict";

// https://github.com/vuejs/vuex/issues/1037
/**
 * Takes a vuex module definition object and adds for all state entries getters and mutations if these are not present.
 * The given module must have at least a state object. Mutations will be called set + state.key with the first character
 * of the key in upper case (eg. state.name becomes setName).
 *
 * @param {Object} The vuex module definition.
 */
export function bootstrap(module) {
  if (module.getters === undefined) {
    module.getters = {};
  }

  if (module.mutations === undefined) {
    module.mutations = {};
  }

  Object.keys(module.state).forEach((key) => {
    if (module.getters[key] === undefined) {
      module.getters[key] = (state) => state[key];
    }

    const setter = "set" + key.charAt(0).toUpperCase() + key.substr(1, key.length);
    if (module.mutations[setter] === undefined) {
      module.mutations[setter] = (state, value) => {
        state[key] = value;
      };
    }
  });

  return module;
}
