<template>
  <v-dialog v-model="showInternal" max-width="400">
    <v-card class="text-xs-left">
      <v-card-title class="headline">Conflicting Changes</v-card-title>
      <v-card-text>
        <p>
          Somebody has changed the comment on the server after you received it. Simply saving your state would overwrite
          the changes on the server. To merge all edits proceed as follows:
        </p>
        <ol>
          <li>Copy the changes you want to keep</li>
          <li>Refresh the comment. (The text in your editor will be overwritten)</li>
          <li>Paste your changes into the refreshed comment</li>
        </ol>
      </v-card-text>
      <v-card-actions>
        <v-btn
          flat
          color="secondary"
          @click="
            $emit('dismissed');
            $emit('refresh');
          "
        >
          Refresh comment
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="$emit('dismissed')"> Close dialog </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["show"],

  watch: {
    show(value) {
      // watch for changes to the outside controlled visibility and store them inside this component
      this.showInternal = value;
    },

    showInternal(value) {
      // watch for changes to the inside controlled visibility and inform outside world if component was closed
      if (!value) {
        this.$emit("dismissed");
      }
    },
  },

  data() {
    return {
      showInternal: this.show,
    };
  },
};
</script>
